main.service("DividendsService", function ($http) {
	"use strict";
	var Dividend = this;

	Dividend.getDividends = function () {
		return $http({
			url: "index.php/getDividends"
		});
	};

	Dividend.getProfits = function () {
		return $http({
			url: "index.php/getProfits"
		});
	};
});
