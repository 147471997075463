main.service("RegistrationFeesService", function ($http) {
	"use strict";
	var Fee = this;

	Fee.getRegistrationFees = function () {
		return $http({
			url: "index.php/getRegistrationFees"
		});
	}

	Fee.getActiveRegistrationFee = function () {
		return $http({
			url: "index.php/getActiveRegistrationFee"
		});
	}

});