'use strict';

angular.module('main').directive('printDiv', function () {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('click', function(evt){    
        evt.preventDefault();    
        PrintElem(attrs.printDiv);
      });

      function PrintElem(elem)
      {
        Print($(elem).html());
      }

      function Print(printContents) 
      {
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
          var popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
          popupWin.window.focus();
          
          popupWin.document.write('<!DOCTYPE html><html><head><title></title><link rel="stylesheet" type="text/css" href="./app/css/resources.min.css" />'  // Your styles here, I needed the margins set up like this
            + '<style>body{font-size:9px;}#first{float: left;width:45%;} #last{float: right;width:45%;align:left;} #div-right{float:left;}.skip{display:none;}a{display:none;}</style>'
            + '</head><body onload="window.print()">'
            + '<div style="padding-bottom:10px;overflow:hidden; width:100%;">' 
            + '</div>'
            + '<hr>'
            + '<div>'
            + printContents
            + '</div></body></html>');

          popupWin.onbeforeunload = function (event) {
            popupWin.close();
            return '.\n';
          };
          popupWin.onabort = function (event) {
            popupWin.document.close();
            popupWin.close();
          }
        } else {
          var popupWin = window.open('', '_blank', 'width=800,height=600');
          popupWin.document.open();
          popupWin.document.write('<!DOCTYPE html><html><head><title></title><link rel="stylesheet" type="text/css" href="./app/css/resources.min.css" />'  // Your styles here, I needed the margins set up like this
            + '<style>body{font-size:9px;}#first{float: left;width:45%;} #last{float: right;width:45%;align:left;} #div-right{float:left;}.skip{display:none;}a{display:none;}</style>'
            + '</head><body onload="window.print()">'
            + '<div style="padding-bottom:10px;overflow:hidden; width:100%;">' 
            + '</div>'
            + '<hr>'
            + '<div>'
            + printContents
            + '</div></body></html>');
          popupWin.document.close();
        }
        popupWin.document.close();

        return true;
      }
    }
  };
});