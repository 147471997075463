angular.module('directives', []).directive('appVersion', ['version',
  function(version) {
   return function(scope, elm, attrs) {
    elm.text(version);
  };
}]);

main.directive('backButton', function(){
  return {
    restrict: 'A', 
    link: function(scope, element, attrs) {
      element.bind('click', goBack); 
      function goBack() {
        history.back();
        scope.$apply();
      }
    }
  };
});
 
main.directive("sgNumberInput", function sgNumberInput($filter, $locale){
        //#region helper methods
        function getCaretPosition(inputField) {
            // Initialize
            var position = 0;
            // IE Support
            if (document.selection) {
                inputField.focus();
                // To get cursor position, get empty selection range
                var emptySelection = document.selection.createRange();
                // Move selection start to 0 position
                emptySelection.moveStart('character', -inputField.value.length);
                // The caret position is selection length
                position = emptySelection.text.length;
            }
            else if (inputField.selectionStart || inputField.selectionStart === 0) {
                position = inputField.selectionStart;
            }
            return position;
        }
        function setCaretPosition(inputElement, position) {
            if (inputElement.createTextRange) {
                var range = inputElement.createTextRange();
                range.move('character', position);
                range.select();
            }
            else {
                if (inputElement.selectionStart) {
                    inputElement.focus();
                    inputElement.setSelectionRange(position, position);
                }
                else {
                    inputElement.focus();
                }
            }
        }
        function countNonNumericChars(value) {
            return (value.match(/[^a-z0-9]/gi) || []).length;
        }
        //#endregion helper methods
        
        
        
        return {
            require: "ngModel",
            restrict: "A",
            link: function ($scope, element, attrs, ctrl) {
                var fractionSize = parseInt(attrs['fractionSize']) || 0;
                var numberFilter = $filter('number');
                //format the view value
                ctrl.$formatters.push(function (modelValue) {
                    var retVal = numberFilter(modelValue, fractionSize);
                    var isValid = !isNaN(modelValue);
                    ctrl.$setValidity(attrs.name, isValid);
                    return retVal;
                });
                //parse user's input
                ctrl.$parsers.push(function (viewValue) {
                    var caretPosition = getCaretPosition(element[0]), nonNumericCount = countNonNumericChars(viewValue);
                    viewValue = viewValue || '';
                    //Replace all possible group separators
                    var trimmedValue = viewValue.trim().replace(/,/g, '').replace(/`/g, '').replace(/'/g, '').replace(/\u00a0/g, '').replace(/ /g, '');
                    //If numericValue contains more decimal places than is allowed by fractionSize, then numberFilter would round the value up
                    //Thus 123.109 would become 123.11
                    //We do not want that, therefore I strip the extra decimal numbers
                    var separator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                    var arr = trimmedValue.split(separator);
                    var decimalPlaces = arr[1];
                    if (decimalPlaces != null && decimalPlaces.length > fractionSize) {
                        //Trim extra decimal places
                        decimalPlaces = decimalPlaces.substring(0, fractionSize);
                        trimmedValue = arr[0] + separator + decimalPlaces;
                    }
                    var numericValue = parseFloat(trimmedValue);
                    var isEmpty = numericValue == null || viewValue.trim() === "";
                    var isRequired = attrs.required || false;
                    var isValid = true;
                    if (isEmpty && isRequired || !isEmpty && isNaN(numericValue)) {
                        isValid = false;
                    }
                    ctrl.$setValidity(attrs.name, isValid);
                    if (!isNaN(numericValue) && isValid) {
                        var newViewValue = numberFilter(numericValue, fractionSize);
                        element.val(newViewValue);
                        var newNonNumbericCount = countNonNumericChars(newViewValue);
                        var diff = newNonNumbericCount - nonNumericCount;
                        var newCaretPosition = caretPosition + diff;
                        if (nonNumericCount === 0 && newCaretPosition > 0) newCaretPosition--;
                        setCaretPosition(element[0], newCaretPosition);
                    }
                    return !isNaN(numericValue) ? numericValue : null;
                });
            } //end of link function
        };
    });



main.directive('uiSelectRequired', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.uiSelectRequired = function(modelValue, viewValue) {
        return modelValue && modelValue.length;
      };
    }
  };
});


main.directive("fileModel",function() {
  return {
    restrict: 'EA',
    scope: {
      setFileData: "&"
    },
    link: function(scope, ele, attrs) {
      ele.on('change', function() {
        scope.$apply(function() {
          var val = ele[0].files[0];
          scope.setFileData({ value: val });
        });
      });
    }
  }
});

main.directive("list", function(restService, $rootScope) {
  return {
    restrict: 'A',
    link: function(scope, el, attrs, ctrl){
      var id = scope.$eval(attrs.list).id;
      var modelname = scope.$eval(attrs.list).modelname; 
      restService.getItem(id, modelname).success(function (data) {
        $rootScope.Item  = data.item;
      });
    },
    template: "<span>{{ Item.nice_name }}</span>"
  }
});


main.directive('includeReplace', function () {
  return {
    require: 'ngInclude',
    restrict: 'A', /* optional */
    link: function (scope, el, attrs) {
      el.replaceWith(el.children());
    }
  };
});


main.directive('fileModel', ['$parse', function ($parse) {
return {
    restrict: 'A',
    link: function(scope, element, attrs) {
        var model = $parse(attrs.fileModel);
        var modelSetter = model.assign;

        element.bind('change', function(){
            scope.$apply(function(){
                modelSetter(scope, element[0].files[0]);
            });
        });
    }
};}]);

main.directive('datepickerLocaldate', ['$parse', function ($parse) {
  var directive = {
    restrict: 'A',
    require: ['ngModel'],
    link: link
  };
  return directive;

  function link(scope, element, attr, ctrls) {
    var ngModelController = ctrls[0];
    
    ngModelController.$parsers.push(function (viewValue) {
     
      viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
      
      return viewValue.toISOString().substring(0, 10);
    });
    
    ngModelController.$formatters.push(function (modelValue) {
      if (!modelValue) {
        return undefined;
      }
      var dt = new Date(modelValue); 
      dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
      return dt;
    });
  }
}]);

main.directive('ngBindAttrs', ngBindAttrsDirective);

  function ngBindAttrsDirective() {
    return {
      restrict: 'A',
      controller: function($scope, $element, $attrs) {
        var attrsObj = $scope.$eval($attrs.ngBindAttrs);
        angular.forEach(attrsObj, function(value, key) {
          $attrs.$set(key, value);
        });
      }
    };
  }

main.directive("selectNgFiles", function() {
  return {
    require: "ngModel",
    link: function postLink(scope,elem,attrs,ngModel) {
      elem.on("change", function(e) {
        var files = elem[0].files;
        ngModel.$setViewValue(files);
      })
    }
  }
});