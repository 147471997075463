main.controller("DividendsController", [
  "$scope",
  "DividendsService",
  "MembersService",
  "$http",
  "$upload",
  "notify",
  function ($scope, DividendsService, MembersService, $http, $upload, notify) {
    "use strict";

    DividendsService.getDividends().success(function (data) {
      $scope.dividends = data.dividends;
    });

    DividendsService.getProfits().success(function (data) {
      $scope.profits = data.profits;
    });

    $scope.calculateDividend = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/calculateDividend",
        params: { formdata: formdata }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DividendsService.getProfits().success(function (data) {
            $scope.profits = data.profits;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.importDividendsExcel = function (sheet) {
      $upload
        .upload({
          url: "importDividendsExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DividendsService.getDividends().success(function (data) {
            $scope.dividends = data.dividends;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $(function () {
      $scope.dateRange4 = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom = Date.parse(daterange["0"]);
        $scope.dateTo = Date.parse(daterange["1"]);
      };

      var start = moment().subtract(29, "days");
      var end = moment();

      function cb(start, end) {
        $("#reportrange4 span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      $("#reportrange4").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cb
      );

      cb(start, end);
    });

    // Pagination Column Index
    $scope.serial = 1;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial =
        newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };

    // Membership Status filter
    $scope.membership_status_filter = {};
    $scope.membership_status_filter.selected = { label: "Current", value: "1" };
    $scope.membership_status_filters = [
      { label: "All", value: "" },
      { label: "Current", value: "1" },
      { label: "Former", value: "0" }
    ];

    // Rows Per Page Filter
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];

    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };

    MembersService.getUserAuthorization().success(function (data) {
      var abilities = data.abilities;
      var fobidden = data.fobidden;

      angular.forEach(abilities, function (abilities) {
        if (abilities.name == "Basic User") {
          $scope.viewMemberInformationOnly = true;
        }
        if (abilities.name == "Export Transactions") {
          $scope.export_transactions = "Yes";
        }
        if (abilities.name == "Import Transactions") {
          $scope.importTransactions = true;
        }
      });

      angular.forEach(fobidden, function (fobidden) {
        if (fobidden.name == "Export Transactions") {
          $scope.export_transactions = "No";
        }
        if (fobidden.name == "Import Transactions") {
          $scope.importTransactions = false;
        }
      });
    });

    $scope.dividends_sample_data = [
      {
        "EFFECTIVE DATE": "2019-03-14",
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        AMOUNT: "50000"
      }
    ];
    $scope.dividends_sample = function () {
      alasql(
        'SELECT * INTO XLSX("dividends-sample.xlsx",{headers:true}) FROM ?',
        [$scope.dividends_sample_data]
      );
    };

    $scope.onFileSelect6 = function ($files) {
      $scope.sheet = $files[0];

      $scope.filename = $scope.sheet.name;

      // No file validation
      if ($scope.sheet.name !== undefined) {
        $scope.filePresent = true;
      } else {
        $scope.filePresent = false;
      }

      // File size validation
      // Filesize in KB
      $scope.filesizeKB = $scope.sheet.size / 1000;
      // Filesize in MB
      $scope.filesizeMB = $scope.sheet.size / 1000000;

      // 20MB maximum file size
      if ($scope.sheet.size < 20000000) {
        $scope.fileSizeValid = true;
      } else {
        $scope.fileSizeValid = false;
      }

      // File type validation
      var validFormats = ["xlsx"];
      var ext = $scope.sheet.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.fileTypeValid = validFormats.includes(ext);
    };
  }
]);
