main.service("DeductionsService", function (
	$http
) {
	"use strict";
	var Deduction = this;

	Deduction.displayDeduction = function () {
		return $http({
			url: "index.php/displayDeduction"
		});
	};

	Deduction.getDeductions = function () {
		return $http({
			url: "index.php/getDeductions"
		});
	};

	Deduction.getActiveDeductions = function () {
		return $http({
			url: "index.php/getActiveDeductions"
		});
	};
});
