main.service('AuditTrailService', function ( $http) {
    'use strict';  
    
	var AuditTrail = this;

    AuditTrail.getAuditTrail = function () {
            return $http({
                            url: 'index.php/getAuditTrail',
                        });
        };
    

});