main.controller("BenefitsController", [
  "$scope",
  "BenefitsService",
  "notify",
  "$http",
  function (
    $scope,
    BenefitsService,
    notify,
    $http
  ) {
    "use strict";


    // Pagination Column Index
    $scope.serial = 1;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial =
        newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };


    // Rows Per Page Filter
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];


    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };


    // Status filter    
    $scope.is_active_filter = {};
    $scope.is_active_filter.selected = { label: "Active", value: "1" };
    $scope.is_active_filters = [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" }
    ];


    $('input[name="start_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="start_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $scope.saveBenefit = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/saveBenefit",
        params: { formdata: formdata }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getBenefits();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.updateBenefit = function (id, formdata) {
      return $http({
        method: "POST",
        url: "index.php/updateBenefit",
        params: { formdata: formdata, id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getBenefits();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteBenefit = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteBenefit",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          $scope.getBenefits();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.getBenefitDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getBenefitDetails",
        params: { id: id }
      }).success(function (data) {
        $scope.benefit = data.benefit;
        $scope.formdata = data.benefit;
      });
    };


    $scope.getBenefits = function() {
      BenefitsService.getBenefits().success(function (data) {
        $scope.benefits = data.benefits;
      });
    }


    // Clear Save Benefit modal
    $scope.clearAddBenefitModal = function() {
      $scope.formdata.name_save = '';
      $scope.formdata.amount_save = null;
      $scope.formdata.start_date_save = '';
      $scope.formdata.end_date_save = '';
      $scope.formdata.status_save = '1';
      $scope.AddBenefitForm.$setPristine();
      $scope.AddBenefitForm.$setUntouched();
    }


  }
]);
