"use strict";
main.service("RestApiService", function (
	notify,
	ModalApiService,
	$modal,
	$http
) {
	var rest = this;

	rest.getItem = function (id, modelname) {
		return $http({
			method: "GET",
			url: "index.php/getItem/",
			params: {
				id: id,
				modelname: modelname
			}
		});
	};

	rest.getList = function (modelname) {
		return $http({
			method: "GET",
			url: "index.php/getList/",
			params: {
				modelname: modelname
			}
		});
	};

	rest.getFields = function (fields, modelname) {
		return $http({
			method: "GET",
			url: "index.php/getFields/",
			params: {
				data: fields,
				modelname: modelname
			}
		});
	};

	rest.getRowItem = function (id, column, modelname) {
		return $http({
			method: "GET",
			url: "index.php/getRowItem/",
			params: {
				id: id,
				column: column,
				modelname: modelname
			}
		});
	};

	rest.getLocalCurrency = function () {
		return $http({
			method: "GET",
			url: "index.php/getLocalCurrency/"
		});
	};

	rest.cancel = function () {
		$modalInstance.dismiss("cancel");
	};

	rest.checkDataExistence = function (column, modelname, formdata) {
		return $http({
			method: "GET",
			url: "index.php/checkDataExistence/",
			params: {
				column: column,
				modelname: modelname,
				formdata: formdata
			}
		});
	};

	rest.openListForm = function (formname, windowClass, Iterator) {
		var modalInstance = $modal.open({
			templateUrl: formname,
			windowClass: windowClass,
			keyboard: false,
			backdrop: "static",
			resolve: {
				datalist: function () {
					return Iterator;
				}
			},
			controller: function ($scope, $modalInstance, datalist, notify) {
				rest.postList = function (dict, modelname) {
					$http({
						method: "POST",
						url: "index.php/postList",
						data: { dict: dict, modelname: modelname }
					})
						.success(function (data, status) {
							datalist.push(data.insertedData);
							$modalInstance.close();
							notify({
								message: data.message,
								template: "pages/notify/success.html",
								position: "center"
							});
						})
						.error(function (error, status) {
							notify({
								message: error.message,
								template: "pages/notify/error.html",
								position: "center"
							});
						});
				};

				rest.postItem = function (dict, modelname) {
					$http({
						method: "POST",
						url: "index.php/postItem",
						data: { dict: dict, modelname: modelname }
					})
						.success(function (data, status) {
							datalist.push(data.insertedData);
							$modalInstance.close();
							notify({
								message: data.message,
								template: "pages/notify/success.html",
								position: "center"
							});
						})
						.error(function (error, status) {
							notify({
								message: error.message,
								template: "pages/notify/error.html",
								position: "center"
							});
						});
				};

				rest.closeListForm = function () {
					$modalInstance.dismiss("cancel");
				};
			}
		});
	};

	rest.OpenMultiModelForm = function (formname, windowClass, Iterator) {
		var modalInstance = $modal.open({
			templateUrl: formname,
			windowClass: windowClass,
			keyboard: false,
			backdrop: "static",
			resolve: {
				multimodelData: function () {
					return Iterator;
				}
			},
			controller: function ($scope, $modalInstance, multimodelData, notify) {
				rest.PostMultiModelForm = function (
					formdata,
					rowsdata,
					majormodel,
					childmodel
				) {
					$http({
						method: "POST",
						url: "index.php/PostMultiModelData",
						data: {
							formdata: formdata,
							rowsdata: rowsdata,
							majormodel: majormodel,
							childmodel: childmodel
						}
					})
						.success(function (data, status) {
							multimodelData.push(data.insertedData);
							$modalInstance.close();
							notify({
								message: data.message,
								template: "pages/notify/success.html",
								position: "center"
							});
						})
						.error(function (error, status) {
							notify({
								message: error.message,
								template: "pages/notify/error.html",
								position: "center"
							});
						});
				};

				rest.CancelMultiModelForm = function () {
					$modalInstance.dismiss("cancel");
				};
			}
		});
	};

	rest.updateRow = function ($index, id, modelname, data, Iterator) {
		$http({
			method: "PUT",
			url: "index.php/updateRow",
			params: { id: id, data: data, modelname: modelname }
		})
			.success(function (data, status) {
				notify({
					message: data.message,
					template: "pages/notify/success.html",
					position: "center"
				});
			})
			.error(function (data, status) {
				notify({
					message:
						"Something went wrong on updating " +
						modelname +
						". Please try Again!",
					template: "pages/notify/error.html",
					position: "center"
				});
			});
	};

	rest.updateColumn = function (data, modelname) {
		$http({
			method: "PUT",
			url: "index.php/updateColumn",
			params: { data: data, modelname: modelname }
		})
			.success(function (data, status) {
				notify({
					message: data.message,
					template: "pages/notify/success.html",
					position: "center"
				});
			})
			.error(function (data, status) {
				notify({
					message:
						"Something went wrong on updating " +
						modelname +
						". Please try Again!",
					template: "pages/notify/error.html",
					position: "center"
				});
			});
	};

	rest.deleteItem = function ($index, id, modelname, Iterator) {
		var modalOptions = {
			closeButtonText: "Cancel",
			actionButtonText: "Delete " + modelname + " ",
			headerText: "Delete " + modelname + " ",
			bodyText: "Are you sure you want to delete this " + modelname + " ?"
		};
		ModalService.showModal({}, modalOptions).then(function (result) {
			$http({
				method: "DELETE",
				url: "index.php/deleteList",
				params: { id: id, modelname: modelname }
			})
				.success(function (data, status, headers, config) {
					Iterator.splice($index, 1);
					notify({
						message: data.message,
						template: "pages/notify/success.html",
						position: "center"
					});
				})
				.error(function (data, status, headers, config) {
					notify({
						message:
							" Something went wrong on deleting " +
							modelname +
							". Please try Again!",
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		});
	};

	rest.deleteList = function (indexes, ids, modelname, Iterator) {
		var modalOptions = {
			closeButtonText: "Cancel",
			actionButtonText: "Delete " + modelname + " ?",
			headerText: "Delete " + modelname + " ",
			bodyText: "Are you sure you want to delete selected " + modelname + " ?"
		};
		ModalService.showModal({}, modalOptions).then(function (result) {
			$http({
				method: "POST",
				url: "index.php/deleteList",
				data: { ids: ids, modelname: modelname }
			})
				.success(function (data, status, headers, config) {
					Iterator.splice(indexes, indexes.length);
					notify({
						message: data.message,
						template: "pages/notify/success.html",
						position: "center"
					});
				})
				.error(function (data, status, headers, config) {
					notify({
						message:
							"Something went wrong on deleting " +
							modelname +
							". Please try Again!",
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		});
	};
});
