main.service("MembersService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Member = this;

	Member.getMemberInfo = function (memberId, a) {
		return $http({
			method: "GET",
			url: "index.php/getMemberInfo",
			params: {
				memberId: memberId,
				a: a
			}
		});
	};

	Member.getMemberInfoOnly = function () {
		return $http({
			method: "GET",
			url: "index.php/getMemberInfoOnly"
		});
	};

	Member.showMembers = function () {
		return $http({
			url: "index.php/showMembers"
		});
	};

	Member.showCurrentMembers = function () {
		return $http({
			url: "index.php/showCurrentMembers"
		});
	};

	Member.showTransactions = function () {
		return $http({
			url: "index.php/showTransactions"
		});
	};

	Member.getContributionTransactions = function () {
		return $http({
			url: "index.php/getContributionTransactions"
		});
	};

	Member.getWithdrawalTransactions = function () {
		return $http({
			url: "index.php/getWithdrawalTransactions"
		});
	};

	Member.getRepaymentTransactions = function () {
		return $http({
			url: "index.php/getRepaymentTransactions"
		});
	};

	Member.getBenefitTransactions = function () {
		return $http({
			url: "index.php/getBenefitTransactions"
		});
	};

	Member.activate = function (id, $index) {
		return $http({
			method: "POST",
			url: "index.php/activate",
			params: {
				id: id,
				$index: $index
			}
		});
	};

	Member.editRow = function (id, variable, field, table) {
		return $http({
			method: "POST",
			url: "index.php/editRow",
			params: {
				id: id,
				variable: variable,
				field: field,
				table: table
			}
		});
	};

	Member.deactivate = function (id, $index) {
		return $http({
			method: "POST",
			url: "index.php/deactivate",
			params: {
				id: id,
				$index: $index
			}
		});
	};

	Member.getUserAuthorization = function () {
		return $http({
			url: "index.php/getUserAuthorization"
		});
	};

	Member.getActiveDeductions = function () {
		return $http({
			url: "index.php/getActiveDeductions"
		});
	};

});
