main.controller("DepartmentsController", [
  "$rootScope",
  "$scope",
  "DepartmentsService",
  "restService",
  "$modal",
  "$log",
  "notify",
  "$filter",
  "$http",
  function (
    $rootScope,
    $scope,
    DepartmentsService,
    restService,
    $modal,
    $log,
    notify,
    $filter,
    $http
  ) {
    "use strict";

    $scope.saveDepartment = function (formdata, action) {
      return $http({
        method: "POST",
        url: "index.php/saveDepartment",
        params: { formdata: formdata }
      })
        .success(function (response) {
          if (action === "clear") {
            $scope.formdata.item_name = "";
            $scope.formdata.description = "";
          }
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DepartmentsService.getDepartments().success(function (data) {
            $scope.departments = data.departments;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.updateDepartment = function (id, formdata) {
      return $http({
        method: "POST",
        url: "index.php/updateDepartment",
        params: { formdata: formdata, id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DepartmentsService.getDepartments().success(function (data) {
            $scope.departments = data.departments;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    // $scope.HNPLC = function () {
    //   var date = "2019-02-01 00:00:00";
    //   return date;
    // };

    $scope.deleteDepartment = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteDepartment",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DepartmentsService.getDepartments().success(function (data) {
            $scope.departments = data.departments;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.getDepartmentDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDepartmentDetails",
        params: { id: id }
      }).success(function (data, status, response) {
        $scope.department = data.department;
        $scope.formdata = data.department;
      });
    };
    DepartmentsService.getDepartments().success(function (data, status) {
      $scope.departments = data.departments;
    });
  }
]);
