main.service("UsersService", function (
	$http
) {
	"use strict";
	var User = this;

	User.showUsers = function () {
		return $http({
			url: "index.php/showUsers"
		});
	};

	User.showProfile = function () {
		return $http({
			url: "index.php/showProfile"
		});
	};

	User.getUserProfile = function () {
		return $http({
			url: "index.php/getUserProfile"
		});
	};
});
