main.controller('AuditTrailController', ['$scope', 'AuditTrailService',
	function($scope, AuditTrailService) {
    
    'use strict';  


    // Serial number in table
    $scope.serial = 1;
    $scope.indexCount = function(newPageNumber){
      
      $scope.itemPerPage=$scope.rowNumber.selected.value;
      $scope.serial = newPageNumber * $scope.itemPerPage - ($scope.itemPerPage-1);
    }


    // Table order filter
    $scope.setOrderProperty = function(propertyName) {
        if ($scope.orderProperty === propertyName) {
            $scope.orderProperty = '-' + propertyName;
        } else if ($scope.orderProperty === '-' + propertyName) {
            $scope.orderProperty = propertyName;
        } else {
            $scope.orderProperty = propertyName;
        }
    } 


    // Date filter
    $(function() {
        $scope.dateRange5 = function (range){
          var daterange = range.split('-');
          $scope.dateFrom = Date.parse(daterange['0']);
          $scope.dateTo =Date.parse(daterange['1']);
        
          };
        
            var start = moment().subtract(29, 'days');
            var end = moment();
        
            function cb(start, end) {
                $('#reportrange span').html(start.format( 'YYYY-MM-DD') + '-' + end.format( 'YYYY-MM-DD'));
            }
        
            $('#reportrange').daterangepicker({
              locale: {
              format: 'MMM DD,YYYY',
              "firstDay": 1,
            },
                showDropdowns: true,
                startDate: start,
                endDate: end,
        
                ranges: {
                   'Today':[moment(), moment()],
                   'Yesterday':[moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                   'Last 7 Days':[moment().subtract(6, 'days'), moment()],
                   'Last 30 Days':[moment().subtract(29, 'days'), moment()],
                   'This Month':[moment().startOf('month'), moment().endOf('month')],
                   'Last Month':[moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                   'This Year':[moment().startOf('year'), moment().endOf('year')],
                   'Last Year':[moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                }
            }, cb);
        
            cb(start, end);
        
    });
  

    // Rows per page filter
    $scope.rowNumber = {};
    $scope.rowNumber.selected ={label: 10, value: 10};
    $scope.rowNumbers = [ 
        {label: 10, value: 10},
        {label: 30, value: 30},
        {label: 50, value: 50},
        {label: 'All', value: ''}
        ];


    // Populate the Audit Trail page
    AuditTrailService.getAuditTrail().then(function(resp){
        $scope.audit_trail = resp.data.audit_trail;
    });


}]); 