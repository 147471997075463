main.service("RolesService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Role = this;

	Role.getUserInfo = function (userId, a) {
		return $http({
			method: "GET",
			url: "index.php/getUserInfo",
			params: {
				userId: userId,
				a: a
			}
		});
	};

	Role.getRoles = function () {
		return $http({
			url: "index.php/getRoles"
		});
	};

	Role.getPermissions = function () {
		return $http({
			url: "index.php/getPermissions"
		});
	};

	Role.getPermissions = function () {
		return $http({
			url: "index.php/getPermissions"
		});
	};
});
