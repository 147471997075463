"use strict";
main.service("restService", function (
  modalService,
  appFactory,
  notify,
  $modal,
  $http
) {
  var rest = this;

  rest.getItem = function (id, modelname) {
    return $http({
      method: "GET",
      url: "index.php/getItem/",
      params: {
        id: id,
        modelname: modelname
      }
    });
  };

  rest.getList = function (modelname) {
    return $http({
      method: "GET",
      url: "index.php/getList/",
      params: {
        modelname: modelname
      }
    });
  };

  rest.getLocalCurrency = function () {
    return $http({
      method: "GET",
      url: "index.php/getLocalCurrency/"
    });
  };

  rest.checkDataExistence = function (column, modelname, formdata) {
    return $http({
      method: "GET",
      url: "index.php/checkDataExistence/",
      params: {
        column: column,
        modelname: modelname,
        formdata: formdata
      }
    });
  };

  rest.openForm = function (formname, windowClass, modelname, Iterator) {
    var modalInstance = $modal.open({
      templateUrl: formname,
      windowClass: windowClass,
      keyboard: false,
      backdrop: "static",
      resolve: {
        tablename: function () {
          return Iterator;
        }
      },
      controller: function ($scope, $modalInstance, tablename, notify) {
        rest.postList = function (dict, modelname, valid) {
          if (valid == 1) {
            appFactory
              .postList(dict, modelname)
              .success(function (data, status) {
                tablename.push(data.insertedData);
                $modalInstance.close();
                notify({
                  message: data.message,
                  template: "pages/notify/success.html",
                  position: "center"
                });
              })
              .error(function (error, status) {
                notify({
                  message: error.message,
                  template: "pages/notify/error.html",
                  position: "center"
                });
              });
          }
        };
        rest.cancel = function () {
          $modalInstance.dismiss("cancel");
        };
      }
    });
  };

  rest.updateRow = function ($index, id, modelname, data, Iterator) {
    appFactory
      .updateRow(id, data, modelname)
      .success(function (data, status) {
        notify({
          message: data.message,
          template: "pages/notify/success.html",
          position: "center"
        });
      })
      .error(function (data, status) {
        notify({
          message:
            "Something went wrong on updating " +
            modelname +
            ". Please try Again!",
          template: "pages/notify/error.html",
          position: "center"
        });
      });
  };

  rest.updateColumn = function (data, modelname) {
    appFactory
      .updateColumn(data, modelname)
      .success(function (data, status) {
        notify({
          message: data.message,
          template: "pages/notify/success.html",
          position: "center"
        });
      })
      .error(function (data, status) {
        notify({
          message:
            "Something went wrong on updating " +
            modelname +
            ". Please try Again!",
          template: "pages/notify/error.html",
          position: "center"
        });
      });
  };

  rest.deleteItem = function ($index, id, modelname, Iterator) {
    var modalOptions = {
      closeButtonText: "Cancel",
      actionButtonText: "Delete " + modelname + " ",
      headerText: "Delete " + modelname + " ",
      bodyText: "Are you sure you want to delete this " + modelname + " ?"
    };
    modalService.showModal({}, modalOptions).then(function (result) {
      appFactory
        .deleteItem(id, modelname)
        .success(function (data, status, headers, config) {
          Iterator.splice($index, 0);
          notify({
            message: data.message,
            template: "pages/notify/success.html",
            position: "center"
          });
        })
        .error(function (data, status, headers, config) {
          notify({
            message:
              "Something went wrong on deleting " +
              modelname +
              ". Please try Again!",
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    });
  };

  rest.deleteList = function (indexes, ids, modelname, Iterator) {
    var modalOptions = {
      closeButtonText: "Cancel",
      actionButtonText: "Delete " + modelname + " ?",
      headerText: "Delete " + modelname + " ",
      bodyText: "Are you sure you want to delete selected " + modelname + " ?"
    };

    modalService.showModal({}, modalOptions).then(function (result) {
      appFactory
        .deleteList(ids, modelname)
        .success(function (data, status, headers, config) {
          Iterator.splice(indexes, indexes.length);
          notify({
            message: data.message,
            template: "pages/notify/success.html",
            position: "center"
          });
        })
        .error(function (data, status, headers, config) {
          notify({
            message:
              "Something went wrong on deleting " +
              modelname +
              ". Please try Again!",
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    });
  };
});
