main.controller("RegistrationFeesController", [
  "$scope",
  "RegistrationFeesService",
  "MembersService",
  "$http",
  "notify",
  function ($scope, RegistrationFeesService, MembersService, $http, notify) {
    "use strict";


    // Pagination Column Index
    $scope.serial = 1;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial =
        newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };


    // Rows Per Page Filter
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];


    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };


    // Status filter    
    $scope.is_active_filter = {};
    $scope.is_active_filter.selected = { label: "Active", value: "1" };
    $scope.is_active_filters = [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" }
    ];


    $('input[name="start_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="start_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    // Permissions
    MembersService.getUserAuthorization().success(function (data) {
      var abilities = data.abilities;
      var fobidden = data.fobidden;

      angular.forEach(abilities, function (abilities) {
        if (abilities.name == "Basic User") {
          $scope.viewMemberInformationOnly = true;
        }
        if (abilities.name == "Export Transactions") {
          $scope.export_transactions = "Yes";
        }
        if (abilities.name == "Import Transactions") {
          $scope.importTransactions = true;
        }
      });

      angular.forEach(fobidden, function (fobidden) {
        if (fobidden.name == "Export Transactions") {
          $scope.export_transactions = "No";
        }
        if (fobidden.name == "Import Transactions") {
          $scope.importTransactions = false;
        }
      });
    });


    $scope.getRegistrationFees = function () {
      RegistrationFeesService.getRegistrationFees().success(function (data) {
        $scope.registration_fees = data.registration_fees;
      });
    };


    $scope.getActiveRegistrationFee = function () {
      RegistrationFeesService.getActiveRegistrationFee().then(function (resp) {
        $scope.active_registration_fee = resp.data.active_registration_fee.registration_fee;
      });
    };


    $scope.getRegistrationFeeDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getRegistrationFeeDetails",
        params: {
          id: id
        }
      })
      .success(function (response) {
        $scope.registration_fee_details = response.registration_fee_details;
      });
    }


    $scope.addRegistrationFee = function (fee, formdata) {
      return $http({
        method: "POST",
        url: "index.php/addRegistrationFee",
        params: { 
                  fee: fee,
                  formdata: formdata
                },
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center",
          });

          $scope.clearAddRegistrationFeeModal();

          RegistrationFeesService.getRegistrationFees().success(function (data) {
            $scope.registration_fees = data.registration_fees;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center",
          });
        });
    };


    // Clear Registration Fee Form and reset status
    $scope.clearAddRegistrationFeeModal = function () {
      $scope.fee_save = null;
      $scope.formdata.start_date_save = "";
      $scope.formdata.end_date_save = "";
      $scope.formdata.status_save = "1";
      $scope.AddRegistrationFeeForm.$setPristine();
      $scope.AddRegistrationFeeForm.$setUntouched();
    };


    $scope.editRegistrationFee = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/editRegistrationFee",
        params: { 
                formdata: formdata
              },
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center",
          });

          RegistrationFeesService.getRegistrationFees().success(function (data) {
            $scope.registration_fees = data.registration_fees;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center",
          });
        });
    };

    
    $scope.deleteRegistrationFee = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteRegistrationFee",
        params: { id: id },
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center",
          });

          RegistrationFeesService.getRegistrationFees().success(function (data) {
            $scope.registration_fees = data.registration_fees;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center",
          });
        });
    };


  },
]);
