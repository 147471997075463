main.service("BenefitsService", function (
	$http
) {
	"use strict";
	var Benefit = this;

	Benefit.getBenefits = function () {
		return $http({
			url: "index.php/getBenefits"
		});
	};

	Benefit.getActiveBenefits = function () {
		return $http({
			url: "index.php/getActiveBenefits"
		});
	};


});
