main.service("LoansService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Loans = this;

	Loans.getLoanInfo = function (loanId, a) {
		return $http({
			method: "GET",
			url: "index.php/getLoanInfo",
			params: {
				loanId: loanId,
				a: a
			}
		});
	};

	Loans.showLoanSetting = function () {
		return $http({
			url: "index.php/showLoanSetting"
		});
	};

	Loans.getAllLoanSettings = function () {
		return $http({
			url: "index.php/getAllLoanSettings"
		});
	};

	Loans.checkBalance = function (loan) {
		return $http({
			method: "GET",
			url: "index.php/checkBalance",
			params: {
				loan: loan
			}
		});
	};

	Loans.showBorrower = function () {
		return $http({
			url: "index.php/showBorrower"
		});
	};

	Loans.showTrustee = function () {
		return $http({
			url: "index.php/showTrustee"
		});
	};

	Loans.showLoan = function () {
		return $http({
			url: "index.php/showLoan"
		});
	};

	// Loans.editLevel = function (id, variable) {
	// 	return $http({
	// 		method: "POST",
	// 		url: "index.php/editLevel",
	// 		params: {
	// 			id: id,
	// 			variable: variable
	// 		}
	// 	});
	// };
});
