main.controller("LoansController", [
  "$rootScope",
  "$state",
  "$stateParams",
  "$scope",
  "LoansService",
  "RolesService",
  "MembersService",
  "restService",
  "$modal",
  "$log",
  "notify",
  "$filter",
  "$upload",
  "$http",
  function (
    $rootScope,
    $state,
    $stateParams,
    $scope,
    LoansService,
    RolesService,
    MembersService,
    restService,
    $modal,
    $log,
    notify,
    $filter,
    $upload,
    $http
  ) {
    "use strict";

    // Rows per Page
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];

    $scope.loanId = $stateParams.loanId;

    // Loan Status Filter
    $scope.loan_filter = {};
    $scope.loan_filter.selected = { label: "All", value: "" };
    $scope.loan_filters = [
      { label: "All", value: "" },
      { label: "Pending", value: "Pending" },
      { label: "Rejected", value: "Rejected" },
      { label: "Approved", value: "Approved" },
      { label: "Issued", value: "Issued" },
      { label: "Current", value: "Current" },
      { label: "Fully Repaid", value: "Fully Repaid" }
    ];

    // Status filter    
    $scope.is_active_filter = {};
    $scope.is_active_filter.selected = { label: "Active", value: "1" };
    $scope.is_active_filters = [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" }
    ];

    // Membership Status filter
    $scope.membership_status_filter = {};
    $scope.membership_status_filter.selected = { label: "Current", value: "1" };
    $scope.membership_status_filters = [
      { label: "All", value: "" },
      { label: "Current", value: "1" },
      { label: "Former", value: "0" }
    ];

    // Pagination Column Index
    $scope.serial = 1;
    var iteration = 0;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial =
        newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };
    var tabClasses;

    function initTabs() {
      tabClasses = ["", "", "", ""];
    }

    $scope.getTabClass = function (tabNum) {
      var n = tabNum;
      return tabClasses[tabNum];
    };

    $scope.getTabPaneClass = function (tabNum) {
      return "tab-pane " + tabClasses[tabNum];
    };

    $scope.setActiveTab = function (tabNum) {
      initTabs();
      tabClasses[tabNum] = "active";
    };

    initTabs();
    $scope.setActiveTab(1);

    $(function () {
      $('input[name="singledate"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        startDate: moment().startOf("hour"),
        endDate: moment()
          .startOf("hour")
          .add(32, "hour"),
        locale: {
          format: "YYYY-MM-DD",
          firstDay: 1
        }
      });
    });


    $('input[name="start_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="start_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });

    // $scope.HNPLC = function () {
    //   var date = "2019-02-01 00:00:00";
    //   return date;
    // };

    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };

    $(function () {
      $scope.dateRange = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom = Date.parse(daterange["0"]);
        $scope.dateTo = Date.parse(daterange["1"]);
      };

      var start = moment().subtract(29, "days");
      var end = moment();

      function cb(start, end) {
        $("#reportrange span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      $("#reportrange").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cb
      );

      cb(start, end);
    });

    $scope.figures = [
      { id: 1, label: "Deposits", value: "Deposits" },
      { id: 2, label: "Savings", value: "Savings" },
      { id: 3, label: "Shares", value: "Shares" },
      { id: 4, label: "Contributions", value: "Contributions" }
    ];

    $scope.methods = [
      { id: 1, label: "Flat Rate" },
      { id: 2, label: "Compound Rate" }
    ];

    $scope.levels = [
      { id: 1, label: "Level 1" },
      { id: 2, label: "Level 2" },
      { id: 3, label: "Level 3" },
      { id: 4, label: "Level 4" }
    ];

    $scope.collateralSettings = [
      { id: 1, label: "Not Required" },
      { id: 2, label: "Required" }
    ];

    $scope.collateral_types = [
      { id: 1, label: "Land" },
      { id: 2, label: "Business Apartment" },
      { id: 3, label: "Car" },
      { id: 4, label: "Other" }
    ];

    $scope.loan = {};
    $scope.reasons = [{}];
    $scope.trustees = [{}];
    $scope.collaterals = [{}];
    $scope.applicationData = [];

    $scope.onFileSelect1 = function ($file, $index) {
      $scope.applicationData["evidence" + $index] = $file[0];
      $scope.evidence_count = $index + 1;
      $scope.file1_validated = "Yes";

      $scope.evidence = $file[0];
      // No evidence validation
      if ($scope.evidence.name !== undefined) {
        $scope.evidencePresent = true;
      } else {
        $scope.evidencePresent = false;
      }

      // evidence size validation
      $scope.evidenceSizeKB = $scope.evidence.size / 1000;
      $scope.evidenceSizeMB = $scope.evidence.size / 1000000;

      // 20MB maximum evidence size
      if ($scope.evidence.size < 20000000) {
        $scope.evidenceSizeValid = true;
      } else {
        $scope.evidenceSizeValid = false;
      }

      // evidence type validation
      var validFormats = ["gif", "png", "jpg", "jpeg", "pdf"];
      var ext = $scope.evidence.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.evidenceTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect2 = function ($file, $index) {
      $scope.applicationData["photo" + $index] = $file[0];
      $scope.photo_count = $index + 1;
      $scope.file2_validated = "Yes";

      $scope.photo = $file[0];

      // No photo validation
      if ($scope.photo.name !== undefined) {
        $scope.photoPresent = true;
      } else {
        $scope.photoPresent = false;
      }

      // photo size validation
      $scope.photoSizeKB = $scope.photo.size / 1000;
      $scope.photoSizeMB = $scope.photo.size / 1000000;

      // 20MB maximum photo size
      if ($scope.photo.size < 20000000) {
        $scope.photoSizeValid = true;
      } else {
        $scope.photoSizeValid = false;
      }

      // photo type validation
      var validFormats = ["gif", "png", "jpg", "jpeg"];
      var ext = $scope.photo.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.photoTypeValid = validFormats.includes(ext);
    };

    $scope.onFileSelect3 = function ($file) {
      $scope.evidence = $file[0];

      // No evidence validation
      if ($scope.evidence.name !== undefined) {
        $scope.evidencePresent = true;
      } else {
        $scope.evidencePresent = false;
      }

      // evidence size validation
      $scope.evidenceSizeKB = $scope.evidence.size / 1000;
      $scope.evidenceSizeMB = $scope.evidence.size / 1000000;

      // 20MB maximum evidence size
      if ($scope.evidence.size < 20000000) {
        $scope.evidenceSizeValid = true;
      } else {
        $scope.evidenceSizeValid = false;
      }

      // evidence type validation
      var validFormats = ["gif", "png", "jpg", "jpeg", "pdf"];
      var ext = $scope.evidence.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.evidenceTypeValid = validFormats.includes(ext);
    };

    $scope.addReason = function (newrow) {
      $scope.reasons.push({});
      $scope.reasons_validated = "No";
      $scope.validate(newrow);
    };

    $scope.removeReason = function ($index) {
      $scope.reasons.splice($index, 1);
      $scope.validate(0);
    };
    $scope.addTrustee = function (newrow) {
      $scope.trustees.push({});
      $scope.trustees_validated = "No";
      $scope.validate(newrow);
    };

    $scope.removeTrustee = function ($index, trustee) {
      $scope.trustees.splice($index, 1);
      $scope.trustees_validated = "No";
      $scope.validate(0);
    };
    $scope.addCollateral = function () {
      $scope.collaterals.push({});
      $scope.completed = "No";
      $scope.file1_validated = "No";
      $scope.file2_validated = "No";
    };

    $scope.removeCollateral = function ($index) {
      $scope.collaterals.splice($index, 1);
      $scope.file1_validated = "Yes";
      $scope.file2_validated = "Yes";
      $scope.validate(0);
    };
    $scope.accept = function (decision, level) {
      if (decision == 0) {
        $scope.decision = "Rejection";
      } else {
        $scope.decision = "Approval";
      }
    };

    $scope.afterSelect = function ($selected, $index) {
      var p = Number($scope.loan.principal || 0);
      var l = Number($scope.loanSetting[0].sponsorship_limit);
      var new_trustee = $scope.eligible_trustee;

      $scope.eligible_trustee = [];

      if ($index >= 0) {
        $scope.trustees_validated = "Yes";
        $scope.validate(0);
      }

      angular.forEach(new_trustee, function (new_trustee) {
        var limit = Number(new_trustee.has_spons || 0) + p;

        if (new_trustee.id !== $selected.id && l >= limit) {
          $scope.eligible_trustee.push(new_trustee);
        }
      });
    };

    $scope.checkMember_eligibility = function () {
      $scope.valid = "No";
      LoansService.showBorrower().success(function (data) {
        $scope.eligible_borrower = [];
        $scope.no_borrower = "Yes";
        // $scope.eligible_trustee = [];
        var borrower = data.borrower;
        var membership_period = Number($scope.loanSetting[0].membership_period);
        var today = moment().format("YYYY-MM-DD");
        var abilities = data.abilities;
        var memberId = data.member_id;
        var readOnly = false;
        var assistLoan = false;
        var viewLoan = false;

        angular.forEach(abilities, function (abilities) {
          if (abilities.name == "Basic User") {
            readOnly = true;
          }
          if (abilities.name == "Add Loans") {
            assistLoan = true;
          }
          if (abilities.name == "View Loans") {
            viewLoan = true;
          }
        });

        if (borrower) {
          angular.forEach(borrower, function (borrower) {
            var has_loan = borrower.has_loan;
            var member_id = borrower.id;

            var numberOfDaysToAdd = (membership_period * 30);
            var borrower_min_eligible_date = moment(borrower.reg_date, "YYYY-MM-DD").add(numberOfDaysToAdd, 'days');
            var b1 = new Date(borrower_min_eligible_date);
            var b2 = new Date(today);
            var can_borrow = b1 <= b2;

            // Read only permission
            if (
              (readOnly && memberId == member_id && !assistLoan) ||
              (readOnly && memberId == member_id && !viewLoan) ||
              (readOnly && memberId == member_id && !assistLoan && !viewLoan)
            ) {
              // if (
              //   moment(today).diff(moment(borrower.reg_date), "months", true) >=
              //   membership_period &&
              //   has_loan == 0
              // ) {
              if (can_borrow && has_loan == 0) {
                $scope.eligible_borrower.push(borrower);
                $scope.no_borrower = "No";
              }
            }
            // No read only permission
            if (
              !readOnly ||
              assistLoan ||
              viewLoan ||
              (readOnly && assistLoan) ||
              (readOnly && viewLoan)
            ) {
              // if (
              //   moment(today).diff(moment(borrower.reg_date), "months", true) >=
              //   membership_period &&
              //   has_loan == 0
              // ) {
                if ( can_borrow && has_loan == 0 ) {
                $scope.eligible_borrower.push(borrower);
                $scope.no_borrower = "No";
              }
            }

          });
        }
      });

      LoansService.showTrustee().success(function (data) {
        $scope.eligible_trustee = [];
        var trustee = data.trustee;
        var membership_period = Number($scope.loanSetting[0].membership_period);
        var today = moment().format("YYYY-MM-DD");

        if (trustee) {
          angular.forEach(trustee, function (trustee) {

            var numberOfDaysToAdd = (membership_period * 30);
            var trustee_min_eligible_date = moment(trustee.reg_date, "YYYY-MM-DD").add(numberOfDaysToAdd, 'days');
    
            var t1 = new Date(trustee_min_eligible_date);
            var t2 = new Date(today);
            var can_be_trustee = t1 <= t2;

            // if (
            //   moment(today).diff(moment(trustee.reg_date), "months", true) >=
            //   membership_period
            // ) {
            if ( can_be_trustee ) {
              $scope.eligible_trustee.push(trustee);
            }
          });
        }
      });
    };

    $scope.validate = function ($index) {
      $scope.valid = "No";
      $scope.difference = $scope.loan.principal;

      if ($scope.trustees_validated == "No") {
        if ($scope.trustees[$index]) {
          if ($scope.trustees[$index].name) {
            $scope.trustees_validated = "Yes";
          }
        }
      }
      if ($scope.reasons[$index]) {
        $scope.total = 0;
        if ($scope.reasons["0"].amount) {
          for (var i = 0; i < $scope.reasons.length; i++) {
            $scope.total += Number($scope.reasons[i].amount || 0);
            $scope.difference = Number($scope.loan.principal) - $scope.total;
          }
        }

        if (
          $scope.reasons[$index].amount &&
          $scope.reasons[$index].description &&
          $scope.difference == 0
        ) {
          $scope.reasons_validated = "Yes";
        }

        if (
          $scope.reasons_validated == "Yes" &&
          $scope.trustees_validated == "Yes"
        ) {
          $scope.valid = "Yes";
          if ($scope.collateral_setting == "Not Required") {
            $scope.completed = "Yes";
          }
        }
      }

      if ($scope.collaterals[$index]) {
        if ($scope.collaterals[$index].type) {
          $scope.completed = "No";
        }

        if (
          $scope.collaterals[$index].price &&
          $scope.file1_validated == "Yes" &&
          $scope.file2_validated == "Yes"
        ) {
          if ($scope.collaterals[$index].type.id == 3) {
            if (
              $scope.collaterals[$index].number &&
              $scope.collaterals[$index].model &&
              $scope.collaterals[$index].make &&
              $scope.collaterals[$index].body_type
            ) {
              $scope.completed = "Yes";
            }
          } else if ($scope.collaterals[$index].type.id == 4) {
            if ($scope.collaterals[$index].description) {
              $scope.completed = "Yes";
            }
          } else {
            if (
              $scope.collaterals[$index].region &&
              $scope.collaterals[$index].district &&
              $scope.collaterals[$index].street &&
              $scope.collaterals[$index].plot
            ) {
              $scope.completed = "Yes";
            }
          }
        }
      }
    };

    $scope.checkLoan_eligibility = function () {
      $scope.valid = "No";
      var p = Number($scope.loan.principal || 0);
      var t = Number($scope.loan.return_period || 0);

      if (p) {
        $scope.afterSelect(0);
      }

      if ($scope.loan.loanCategory) {
        var r = Number($scope.loan.loanCategory.rate || 0);
        $scope.collateral_setting = $scope.loan.loanCategory.collateral_setting;
        if ($scope.loan.borrower) {
          //Member Contributions
          var s = Number($scope.loan.borrower.savings);
          var d = Number($scope.loan.borrower.deposits);
          var sh = Number($scope.loan.borrower.shares);
          var c = Number($scope.loan.borrower.contributions);

          //Checking the maximum amount a Borrower can Apply depending on Contributions(c)
          var f = Number($scope.loanSetting[0].multiplication_factor);
          var c1 = Number($scope.loanSetting[0].is_shares);
          var c2 = Number($scope.loanSetting[0].is_saving);
          var c3 = Number($scope.loanSetting[0].is_deposit);
          var c4 = Number($scope.loanSetting[0].is_contribution);
          $scope.allowed_loan = f * (c1 * sh + c2 * s + c3 * d + c4 * c);
          $scope.max = Number($scope.loan.loanCategory.max_loan || 0);
          $scope.min = Number($scope.loan.loanCategory.min_loan || 0);
          $scope.max_return_period = Number(
            $scope.loan.loanCategory.max_return_period || 0
          );

          if ("Flat Rate" == $scope.loan.loanCategory.interest_method.trim()) {
            var interest = p * t * (r / 100 / 12);
            $scope.Loan_interest = interest;
            $scope.Pay_back = p + interest || 0;
            $scope.Deductions = $scope.Pay_back / t;
            $scope.Interest_Repayment_Per_Month = interest / t || 0;
            $scope.Principal_Repayment_Per_Month = p / t || 0;
          }

          if (
            $scope.loan.return_period <= $scope.max_return_period &&
            p <= $scope.max &&
            p >= $scope.min
          ) {
            $scope.valid = "Yes";
          }
        }
      }
    };

    $scope.saveLoan = function (loan, reasons, trustees, collaterals) {
      $scope.applicationData["evidence_count"] = $scope.evidence_count;
      $scope.applicationData["photo_count"] = $scope.photo_count;
      $scope.applicationData["interest"] = $scope.Loan_interest;
      $scope.applicationData["pay_back"] = $scope.Pay_back;
      $scope.applicationData["installment"] = $scope.Deductions;
      $scope.applicationData["interest_per_month"] =
        $scope.Interest_Repayment_Per_Month;
      $scope.applicationData["principal_per_month"] =
        $scope.Principal_Repayment_Per_Month;
      $scope.applicationData["loan"] = loan;
      $scope.applicationData["reasons"] = reasons;
      $scope.applicationData["trustees"] = trustees;
      $scope.applicationData["collaterals"] = collaterals;

      $scope.upload = $upload
        .upload({
          url: "saveLoan",

          data: $scope.applicationData,
          headers: { "Content-Type": undefined }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          LoansService.showLoan().success(function (data, status) {
            $scope.loans = data.loan;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.selectedFigures = [];

    $scope.customText = { buttonDefaultText: "Select Figures To Multiply" };
    $scope.Mysettings = {
      template: "<b>{{option.label}}</b>",
      styleActive: true,
      keyboardControls: true
    };

    $scope.getAllLoanSettings = function () {
      LoansService.getAllLoanSettings().success(function (data) {
        $scope.loanSetting = data.loan_setting;
        $scope.loanCategory = data.loan_category;

        var abilities = data.abilities;
        var fobidden = data.fobidden;
  
        angular.forEach(abilities, function (abilities) {
          if (abilities.name == "Create Loan Categories") {
            $scope.create_cat = "Yes";
          } else if (abilities.name == "Create Loan Settings") {
            $scope.create_set = "Yes";
          }
        });
  
        angular.forEach(fobidden, function (fobidden) {
          if (fobidden.name == "Create Loan Categories") {
            $scope.create_cat = "No";
          } else if (fobidden.name == "Create Loan Settings") {
            $scope.create_set = "No";
          }
        });
  
        if ($scope.create_set == "No") {
          $scope.setActiveTab(2);
        }

      });
    }

    $scope.saveLoanSettings = function (formdata, selectedFigures) {
      return $http({
        method: "POST",
        url: "index.php/saveLoanSettings",
        params: {
          "selectedFigures[]": selectedFigures,
          formdata: formdata
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data) {
          //   $scope.loanSetting = data.loan_setting;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanSetting = data.loan_setting;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.clearAddGeneralLoanSettingsModal = function () {
      $scope.selectedFigures = [];
      $scope.formdata.status_save = '1';
      $scope.formdata.start_date_save = '';
      $scope.formdata.end_date_save = '';
      $scope.formdata.e_m_period_save = null;
      $scope.formdata.sponsorship_limit_save = null;
      // $scope.formdata.limit_save = null;
      $scope.formdata.max_loan_save = null;
      $scope.formdata.factor_save = null;
      $scope.AddGeneralLoanSettingsForm.$setPristine();
      $scope.AddGeneralLoanSettingsForm.$setUntouched();
    }

    $scope.getLoanDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getLoanDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.loan_details = data.loan_details;
      });
    };

    $scope.deleteloan = function (loan) {
      return $http({
        method: "POST",
        url: "index.php/deleteloan",
        params: {
          loan: loan
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          LoansService.showLoan().success(function (data, status) {
            $scope.loans = data.loan;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.issueLoan = function (loan, level) {
      return $http({
        method: "POST",
        url: "index.php/issueLoan",
        params: {
          loan: loan,
          level: level
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          LoansService.showLoan().success(function (data, status) {
            $scope.loans = data.loan;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.saveLoanCategory = function (formdata, cat_method, collateral) {
      return $http({
        method: "POST",
        url: "index.php/saveLoanCategory",
        params: {
          formdata: formdata,
          cat_method: cat_method,
          collateral: collateral
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data) {
          //   $scope.loanCategory = data.loan_category;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanCategory = data.loan_category;
          });
          
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.clearAddLoanCategorySettingsModal = function () {
      $scope.formdata2.cat_name_save = '';
      $scope.formdata2.status_save = '1';
      $scope.formdata2.start_date_save = '';
      $scope.formdata2.end_date_save = '';
      $scope.formdata2.min_loan_save = null;
      $scope.formdata2.max_loan_save = null;
      if ($scope.methods.id) {
        $scope.methods.id.selected = '';
      }
      $scope.formdata2.rate_save = null;
      $scope.formdata2.ret_period_save = null;
      if ($scope.collateralSettings.id) {
        $scope.collateralSettings.id.selected = '';
      }
      $scope.AddLoanCategorySettingsForm.$setPristine();
      $scope.AddLoanCategorySettingsForm.$setUntouched();
    }

    $scope.editLoanCategory = function (formdata, cat_method, collateral) {
      return $http({
        method: "POST",
        url: "index.php/editLoanCategory",
        params: {
          formdata: formdata,
          cat_method: cat_method,
          collateral: collateral
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data, status) {
          //   $scope.loanCategory = data.loan_category;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanCategory = data.loan_category;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };
    $scope.getLoanSettingDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getLoanSettingDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata = data.loanSetting;
      });
    };

    $scope.getLoanCategoryDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getLoanCategoryDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata2 = data.loanCategory;
        $scope.select = data.loanCategory.interest_method;
      });
    };

    $scope.editLoanSetting = function (formdata, selectedFigures) {
      return $http({
        method: "POST",
        url: "index.php/editLoanSetting",
        params: {
          formdata: formdata,
          "selectedFigures[]": selectedFigures
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data) {
          //   $scope.loanSetting = data.loan_setting;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanSetting = data.loan_setting;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.deleteLoanSetting = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteLoanSetting",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data) {
          //   $scope.loanSetting = data.loan_setting;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanSetting = data.loan_setting;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.importLoansExcel = function (sheet) {
      $upload
        .upload({
          url: "importLoansExcel",
          data: {
            sheet: sheet
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          LoansService.showLoan().success(function (data, status) {
            $scope.loans = data.loan;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.onFileSelect6 = function ($files) {
      $scope.sheet = $files[0];

      $scope.filename = $scope.sheet.name;

      // No file validation
      if ($scope.sheet.name !== undefined) {
        $scope.filePresent = true;
      } else {
        $scope.filePresent = false;
      }

      // File size validation
      // Filesize in KB
      $scope.filesizeKB = $scope.sheet.size / 1000;
      // Filesize in MB
      $scope.filesizeMB = $scope.sheet.size / 1000000;

      // 20MB maximum file size
      if ($scope.sheet.size < 20000000) {
        $scope.fileSizeValid = true;
      } else {
        $scope.fileSizeValid = false;
      }

      // File type validation
      var validFormats = ["xlsx"];
      var ext = $scope.sheet.name
        .split(".")
        .pop()
        .toLowerCase();
      $scope.fileTypeValid = validFormats.includes(ext);
    };

    $rootScope.getLoanInfo = function (loanId, a) {
      LoansService.getLoanInfo(loanId, a)
        .success(function (data, status) {
          $scope.status = "Pending";
          $scope.loanInfo = data.loan;
          $scope.abilities = data.abilities;
          $scope.statuses = data.statuses;
          $scope.trustees = data.trustees;
          $scope.collaterals = data.collaterals;
          $scope.additionalDocuments = data.additional_documents;
          $scope.fobidden = data.fobidden;
          $scope.nextId = data.nextId;
          $scope.prevId = data.prevId;
          $scope.lv1 = data.status1;
          $scope.lv2 = data.status2;
          $scope.lv3 = data.status3;
          $scope.lv4 = data.status4;
          $scope.lv5 = data.status5;
          $scope.lv6 = data.status6;
          $scope.lv7 = data.status7;
          $scope.stateOne = "deny";

          if ($scope.additionalDocuments.length == 0) {
            $scope.noAdditionalDocument = true;
          } else {
            $scope.noAdditionalDocument = false;
          }

          $scope.allowAccess();

          if (a && data.loan) {
            $state.go("#/loans/info/:loanId", { loanId: data.loan.id });
          }

          // Permissions for viewing Next/Previous Menu Bar and Trustee No of Loans
          var abilities = data.abilities;
          var readOnly;
          var assistLoan;
          var viewLoan;
          angular.forEach(abilities, function (abilities) {
            if (abilities.name == "Basic User") {
              readOnly = true;
            }
            if (abilities.name == "Add Loans") {
              assistLoan = true;
            }
            if (abilities.name == "View Loans") {
              viewLoan = true;
            }
          });

          // Read only permission
          if (
            (readOnly && !assistLoan) ||
            (readOnly && !viewLoan) ||
            (readOnly && !assistLoan && !viewLoan)
          ) {
            $scope.viewAllLoans = false;
          }
          // No read only permission
          if (assistLoan || viewLoan) {
            $scope.viewAllLoans = true;
          }
        })
        .error(function (data, status) {
          alert(data);
        });
    };

    $scope.getMemberLoanInfo = function (loanId) {
      return $http({
        method: "GET",
        url: "index.php/getMemberLoanInfo",
        params: {
          loanId: loanId
        }
      })
        .success(function (data, status) {
          $scope.status = "Pending";
          $scope.loanInfo = data.loan;
          $scope.abilities = data.abilities;
          $scope.statuses = data.statuses;
          $scope.trustees = data.trustees;
          $scope.collaterals = data.collaterals;
          $scope.additionalDocuments = data.additional_documents;
          $scope.fobidden = data.fobidden;
          $scope.nextId = data.nextId;
          $scope.prevId = data.prevId;
          $scope.lv1 = data.status1;
          $scope.lv2 = data.status2;
          $scope.lv3 = data.status3;
          $scope.lv4 = data.status4;
          $scope.lv5 = data.status5;
          $scope.stateOne = "deny";

          if ($scope.additionalDocuments.length == 0) {
            $scope.noAdditionalDocument = true;
          } else {
            $scope.noAdditionalDocument = false;
          }

          $scope.allowAccess();

        })
        .error(function (data, status) {
          alert(data);
        });
    };

    $scope.deleteLoanCategory = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteLoanCategory",
        params: { id: id }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // LoansService.showLoanSetting().success(function (data) {
          //   $scope.loanCategory = data.loan_category;
          // });

          LoansService.getAllLoanSettings().success(function (data) {
            $scope.loanCategory = data.loan_category;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.approveLoan = function (loanInfo, approval, level) {
      $upload
        .upload({
          url: "approveLoan",
          data: {
            loanInfo: loanInfo,
            approval: approval,
            level: level
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });
          $rootScope.getLoanInfo(loanInfo.id);
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };
    $scope.rejectLoan = function (formdata, level, loan, decision) {
      return $http({
        method: "POST",
        url: "index.php/rejectLoan",
        params: {
          formdata: formdata,
          level: level,
          loan: loan,
          decision: decision
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });
          $rootScope.getLoanInfo(loan.id);
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.resolveLoan = function (loan, level) {
      return $http({
        method: "POST",
        url: "index.php/resolveLoan",
        params: {
          loan: loan,
          level: level
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });
          $rootScope.getLoanInfo(loan.id);
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $rootScope.checkBalance = function (loan) {
      LoansService.checkBalance(loan)
        .success(function (data) {
          $scope.month_loan = data.month_loan;

          if ($scope.loanSetting.length != 0) {
            var month_limit = Number($scope.loanSetting[0].max_loan_amount) / 12;
            if ($scope.month_loan > month_limit) {
              $scope.can_get = "No";
            } else {
              $scope.can_get = "Yes";
            }
          } else {
            $scope.can_get = "No active loan setting";
          }

        })
        .error(function () {
          alert("something went wrong");
        });
    };

    $scope.modifyLoan = function () {
      $scope.can_get = "Yes";
    };

    $scope.allowAccess = function () {
      var abilities = $scope.abilities;

      // Making approval depend on previous approvals
      //
      // $scope.level = [];

      angular.forEach(abilities, function (abilities) {
        if (abilities.name == "Approve Loans (LEVEL 1)") {
          $scope.stateOne = "Allow"; // Show Export Trustees, Reject Loan, Approve Loan buttons
          $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          $scope.level = "level_1";
          // $scope.level.push('level_1');
        }
        if (abilities.name == "Approve Loans (LEVEL 2)") {
          $scope.stateOne = "Allow"; // Show Export Trustees, Reject Loan, Approve Loan buttons
          $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          $scope.level = "level_2";
          // $scope.level.push('level_2');
        }
        if (abilities.name == "Approve Loans (LEVEL 3)") {
          $scope.stateOne = "Allow"; // Show Export Trustees, Reject Loan, Approve Loan buttons
          $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          $scope.level = "level_3";
          // $scope.level.push('level_3');
        }
        if (abilities.name == "Approve Loans (LEVEL 4)") {
          $scope.stateOne = "Allow"; // Show Export Trustees, Reject Loan, Approve Loan buttons
          $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          $scope.level = "level_4";
          // $scope.level.push('level_4');
        }
      });

      // Making approval depend on previous approvals
      //
      //       var Level = $scope.level;

      //  angular.forEach(Level, function (Level){

      //   if (Level == 'level_1') {

      //               $scope.stateOne = "Allow";        // Show Export Trustees, Reject Loan, Approve Loan buttons
      //               $scope.lock = "No";               // Enable Export Trustees, Reject Loan & Approve Loan buttons

      //               if ($scope.lv1) {
      //                   if ($scope.lv1.name == "Rejected") {
      //                     $scope.stateOne = "deny";     // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     $scope.stateTwo = "Allow";    // Show Cancel Loan Rejection button
      //                     }
      //                   else if ($scope.lv1.name == "Approved") {
      //                     $scope.stateThree = "Allow";  // Show Explore button
      //                     $scope.stateTwo = "deny";     // Hide Cancel Loan Rejection button
      //                     $scope.stateOne = "deny";     // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     }
      //                   else {
      //                     $scope.stateTwo = "deny";     // Hide Cancel Loan Rejection button
      //                     }
      //                 }

      //               // Making approval depend on previous approvals
      //               //
      //               // if ($scope.loanInfo.is_approved == 0){
      //               //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //               // } else {
      //               //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
      //               // }

      //             }

      //           if (Level == 'level_2') {

      //               if ($scope.lv1) {
      //                   if ($scope.lv1.name == "Approved") {
      //                     $scope.lock = "No";          // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                     }
      //                   if ($scope.lv1.name == "Rejected") {
      //                     $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
      //                   }
      //               }

      //               if ($scope.lv2) {
      //                   if ($scope.lv2.name == "Rejected") {
      //                     $scope.stateOne = "deny";    // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     $scope.stateTwo = "Allow";   // Show Cancel Loan Rejection button
      //                     }
      //                   else if ($scope.lv2.name == "Approved") {
      //                     $scope.stateThree = "Allow"; // Show Explore button
      //                     $scope.stateTwo = "deny";    // Hide Cancel Loan Rejection button
      //                     $scope.stateOne = "deny";    // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     }
      //                   else {
      //                     $scope.stateTwo = "deny";    // Hide Cancel Loan Rejection button
      //                     }
      //                 }

      //                 // Making approval depend on previous approvals
      //                 //
      //                 // if (!$scope.lv1) {
      //                 //     $scope.lock = "Yes";
      //                 //   }

      //                 // if ($scope.loanInfo.is_approved < 1){
      //                 //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
      //                 // } else {
      //                 //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                 // }

      //             }

      //           if (Level == 'level_3') {

      //               if ($scope.lv1) {
      //                   if ($scope.lv1.name == "Approved") {
      //                     $scope.lock = "No";          // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                     }
      //                   if ($scope.lv1.name == "Rejected") {
      //                     $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
      //                   }
      //                 }

      //               if ($scope.lv2) {
      //                   if ($scope.lv2.name == "Approved") {
      //                     $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                     }
      //                   if ($scope.lv2.name == "Rejected") {
      //                     $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
      //                   }
      //                 }

      //               if ($scope.lv3) {
      //                   if ($scope.lv3.name == "Rejected") {
      //                     $scope.stateOne = "deny";      // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     $scope.stateTwo = "Allow";     // Show Cancel Loan Rejection button
      //                     }
      //                   else if ($scope.lv3.name == "Approved") {
      //                     $scope.stateThree = "Allow";   // Show Explore button
      //                     $scope.stateTwo = "deny";      // Hide Cancel Loan Rejection button
      //                     $scope.stateOne = "deny";      // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     }

      //                   else {
      //                     $scope.stateTwo = "deny";      // Hide Cancel Loan Rejection button
      //                     }
      //                 }

      //                 // Making approval depend on previous approvals
      //                 //
      //                 // if (!$scope.lv1 || !$scope.lv2) {
      //                 //   $scope.lock = "Yes";
      //                 // }

      //                 // if ($scope.loanInfo.is_approved < 2){
      //                 //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
      //                 // } else {
      //                 //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                 // }

      //             }

      //           if (Level == 'level_4') {

      //               if ($scope.lv1) {
      //                 if ($scope.lv1.name == "Approved") {
      //                   $scope.lock = "No";          // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                   }
      //                 if ($scope.lv1.name == "Rejected") {
      //                   $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ??
      //                 }
      //               }

      //             if ($scope.lv2) {
      //                 if ($scope.lv2.name == "Approved") {
      //                   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                   }
      //                 if ($scope.lv2.name == "Rejected") {
      //                   $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ??
      //                 }
      //               }

      //               if ($scope.lv3) {
      //                   if ($scope.lv3.name == "Approved") {
      //                     $scope.lock = "No";             // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //                     }
      //                   if ($scope.lv3.name == "Rejected") {
      //                     $scope.lock = "Yes";        // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
      //                   }
      //                 }

      //               if ($scope.lv4) {
      //                   if ($scope.lv4.name == "Rejected") {
      //                     $scope.stateOne = "deny";       // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     $scope.stateTwo = "Allow";      // Show Cancel Loan Rejection button
      //                     }
      //                   else if ($scope.lv4.name == "Approved") {
      //                     $scope.stateThree = "Allow";    // Show Explore button
      //                     $scope.stateTwo = "deny";       // Hide Cancel Loan Rejection button
      //                     $scope.stateOne = "deny";       // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                     }
      //                   else {
      //                     $scope.stateTwo = "deny";       // Hide Cancel Loan Rejection button
      //                     }
      //                 }

      //               // Making approval depend on previous approvals
      //               //
      //               // if ($scope.loanInfo.is_approved < 3){
      //               //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
      //               // } else {
      //               //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
      //               // }

      //             }

      //             if ($scope.lv5) {
      //               if ($scope.lv5.name == "Issued") {
      //                 $scope.stateThree = "Allow";   // Show Explore button
      //                 $scope.stateTwo = "deny";      // Hide Cancel Loan Rejection button
      //                 $scope.stateOne = "deny";      // Hide Export Trustees, Reject Loan, Approve Loan buttons
      //                 }
      //               }

      //  });

      if ($scope.level == "level_1") {
        $scope.stateOne = "Allow"; // Show Export Trustees, Reject Loan, Approve Loan buttons
        $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons

        if ($scope.lv1) {
          if ($scope.lv1.name == "Rejected") {
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
            $scope.stateTwo = "Allow"; // Show Cancel Loan Rejection button
          } else if ($scope.lv1.name == "Approved") {
            $scope.stateThree = "Allow"; // Show Explore button
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
          } else {
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
          }
        }

        // Making approval depend on previous approvals
        //
        // if ($scope.loanInfo.is_approved == 0){
        //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
        // } else {
        //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
        // }
        if ($scope.lv2) {
          if ($scope.lv2.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv2.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ??
          }
        }

        if ($scope.lv3) {
          if ($scope.lv3.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv3.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv4) {
          if ($scope.lv4.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv4.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }
      }

      if ($scope.level == "level_2") {
        if ($scope.lv1) {
          if ($scope.lv1.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv1.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv2) {
          if ($scope.lv2.name == "Rejected") {
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
            $scope.stateTwo = "Allow"; // Show Cancel Loan Rejection button
          } else if ($scope.lv2.name == "Approved") {
            $scope.stateThree = "Allow"; // Show Explore button
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
          } else {
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
          }
        }

        // Making approval depend on previous approvals
        //
        // if (!$scope.lv1) {
        //     $scope.lock = "Yes";
        //   }

        // if ($scope.loanInfo.is_approved < 1){
        //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
        // } else {
        //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
        // }
        if ($scope.lv3) {
          if ($scope.lv3.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv3.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv4) {
          if ($scope.lv4.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv4.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }
      }

      if ($scope.level == "level_3") {
        if ($scope.lv1) {
          if ($scope.lv1.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv1.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv2) {
          if ($scope.lv2.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv2.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv3) {
          if ($scope.lv3.name == "Rejected") {
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
            $scope.stateTwo = "Allow"; // Show Cancel Loan Rejection button
          } else if ($scope.lv3.name == "Approved") {
            $scope.stateThree = "Allow"; // Show Explore button
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
          } else {
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
          }
        }

        if ($scope.lv4) {
          if ($scope.lv4.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv4.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }
        // Making approval depend on previous approvals
        //
        // if (!$scope.lv1 || !$scope.lv2) {
        //   $scope.lock = "Yes";
        // }

        // if ($scope.loanInfo.is_approved < 2){
        //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
        // } else {
        //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
        // }
      }

      if ($scope.level == "level_4") {
        if ($scope.lv1) {
          if ($scope.lv1.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv1.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ??
          }
        }

        if ($scope.lv2) {
          if ($scope.lv2.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv2.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ??
          }
        }

        if ($scope.lv3) {
          if ($scope.lv3.name == "Approved") {
            $scope.lock = "No"; // Enable Export Trustees, Reject Loan & Approve Loan buttons
          }
          if ($scope.lv3.name == "Rejected") {
            $scope.lock = "Yes"; // Disable Export Trustees, Reject Loan & Approve Loan buttons  ???
          }
        }

        if ($scope.lv4) {
          if ($scope.lv4.name == "Rejected") {
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
            $scope.stateTwo = "Allow"; // Show Cancel Loan Rejection button
          } else if ($scope.lv4.name == "Approved") {
            $scope.stateThree = "Allow"; // Show Explore button
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
            $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
          } else {
            $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
          }
        }

        // Making approval depend on previous approvals
        //
        // if ($scope.loanInfo.is_approved < 3){
        //   $scope.lock = "Yes";            // Disable Export Trustees, Reject Loan & Approve Loan buttons
        // } else {
        //   $scope.lock = "No";            // Enable Export Trustees, Reject Loan & Approve Loan buttons
        // }
      }

      if ($scope.lv5) {
        if ($scope.lv5.name == "Issued") {
          $scope.stateThree = "Allow"; // Show Explore button
          $scope.stateTwo = "deny"; // Hide Cancel Loan Rejection button
          $scope.stateOne = "deny"; // Hide Export Trustees, Reject Loan, Approve Loan buttons
        }
      }
    };

    // $rootScope.editLevel = function (id, variable) {
    //   LoansService.editLevel(id, variable)
    //     .success(function (data, status) { })
    //     .error(function (data, status) {
    //       alert("Something went wrong");
    //     });
    // };

    RolesService.getRoles().success(function (data, status) {
      $scope.roles = data.roles;
    });

    $scope.showLoanSetting = function () {
      LoansService.showLoanSetting().success(function (data) {
        $scope.loanSetting = data.loan_setting;
        $scope.loanCategory = data.loan_category;
  
        // var abilities = data.abilities;
        // var fobidden = data.fobidden;
  
        // angular.forEach(abilities, function (abilities) {
        //   if (abilities.name == "Create Loan Categories") {
        //     $scope.create_cat = "Yes";
        //   } else if (abilities.name == "Create Loan Settings") {
        //     $scope.create_set = "Yes";
        //   }
        // });
  
        // angular.forEach(fobidden, function (fobidden) {
        //   if (fobidden.name == "Create Loan Categories") {
        //     $scope.create_cat = "No";
        //   } else if (fobidden.name == "Create Loan Settings") {
        //     $scope.create_set = "No";
        //   }
        // });
  
        // if ($scope.create_set == "No") {
        //   $scope.setActiveTab(2);
        // }
      });
    }

    $scope.showLoan = function () {
      LoansService.showLoan().success(function (data) {
        $scope.loans = data.loan;
        var abilities = data.abilities;
        var fobidden = data.fobidden;
        $scope.viewMemberInformationOnly = false;
        var buttonCount = 0;
  
        angular.forEach(abilities, function (abilities) {
          if (abilities.name == "Basic User") {
            $scope.viewMemberInformationOnly = true;
          }
          if (abilities.name == "Add Loans") {
            $scope.add_loan = "Yes";
            buttonCount++;
          }
          if (abilities.name == "View Loans") {
            $scope.view_loan = "Yes";
          }
          if (abilities.name == "Issue Loans") {
            $scope.issue_loan = "Yes";
            buttonCount++;
          }
          if (abilities.name == "Export Loans") {
            $scope.export_loan = "Yes";
          }
          // Copied Level detail information
          if (abilities.name == "Approve Loans (LEVEL 1)") {
            $scope.stateOne = "Allow";
            $scope.lock = "No";
            $scope.level = "level_1";
            buttonCount++;
          }
          if (abilities.name == "Approve Loans (LEVEL 2)") {
            $scope.stateOne = "Allow";
            $scope.lock = "No";
            $scope.level = "level_2";
            buttonCount++;
          }
          if (abilities.name == "Approve Loans (LEVEL 3)") {
            $scope.stateOne = "Allow";
            $scope.lock = "No";
            $scope.level = "level_3";
            buttonCount++;
          }
          if (abilities.name == "Approve Loans (LEVEL 4)") {
            $scope.stateOne = "Allow";
            $scope.lock = "No";
            $scope.level = "level_4";
            buttonCount++;
          }
          if (abilities.name == "Import Transactions") {
            $scope.importTransactions = true;
          }
        });
  
        angular.forEach(fobidden, function (fobidden) {
          if (fobidden.name == "Add Loans") {
            $scope.add_loan = "No";
          }
          if (fobidden.name == "View Loans") {
            $scope.view_loan = "No";
          }
          if (fobidden.name == "Issue Loans") {
            $scope.issue_loan = "No";
          }
          if (fobidden.name == "Export Loans") {
            $scope.export_loan = "No";
          }
        });
  
        $scope.buttonCount = buttonCount;
      });
    }

    $scope.loans_sample_data = [
      {
        "MEMBER ID": "1",
        NAME: "Juma Emmanuel Agustino",
        "APPLICATION DATE": "2019-03-14",
        "ISSUE DATE": "2019-04-20",
        CATEGORY: "Normal Loan",
        "LOAN AMOUNT": "1000000",
        "RETURN PERIOD (MONTHS)": "12"
      }
    ];
    $scope.loans_sample = function () {
      alasql('SELECT * INTO XLSX("loans-sample.xlsx",{headers:true}) FROM ?', [
        $scope.loans_sample_data
      ]);
    };

    // Additional Documents in Loan Information
    $scope.onFileSelect7 = function ($file) {
      $scope.additionalDocument1 = $file[0];
      $scope.additionalDocument2 = $file[1];
      $scope.additionalDocument3 = $file[2];
      $scope.additionalDocument4 = $file[3];
      $scope.additionalDocument5 = $file[4];

      // Max number of files exceeded
      if ($file.length > 5) {
        $scope.filesExceeded = true;
      } else {
        $scope.filesExceeded = false;
      }

      // Check the presence of files
      if ($scope.additionalDocument1 !== undefined) {
        $scope.additionalDocument1Present = true;
      } else {
        $scope.additionalDocument1Present = false;
      }

      if ($scope.additionalDocument2 !== undefined) {
        $scope.additionalDocument2Present = true;
      } else {
        $scope.additionalDocument2Present = false;
      }

      if ($scope.additionalDocument3 !== undefined) {
        $scope.additionalDocument3Present = true;
      } else {
        $scope.additionalDocument3Present = false;
      }

      if ($scope.additionalDocument4 !== undefined) {
        $scope.additionalDocument4Present = true;
      } else {
        $scope.additionalDocument4Present = false;
      }

      if ($scope.additionalDocument5 !== undefined) {
        $scope.additionalDocument5Present = true;
      } else {
        $scope.additionalDocument5Present = false;
      }

      // Size validation
      if ($scope.additionalDocument1 !== undefined) {
        $scope.additionalDocument1SizeKB =
          $scope.additionalDocument1.size / 1000;
        $scope.additionalDocument1SizeMB =
          $scope.additionalDocument1.size / 1000000;

        // 20MB maximum additionalDocument size
        if ($scope.additionalDocument1.size < 20000000) {
          $scope.additionalDocument1SizeValid = true;
        } else {
          $scope.additionalDocument1SizeValid = false;
        }
      }

      if ($scope.additionalDocument2 !== undefined) {
        $scope.additionalDocument2SizeKB =
          $scope.additionalDocument2.size / 1000;
        $scope.additionalDocument2SizeMB =
          $scope.additionalDocument2.size / 1000000;

        // 20MB maximum additionalDocument size
        if ($scope.additionalDocument2.size < 20000000) {
          $scope.additionalDocument2SizeValid = true;
        } else {
          $scope.additionalDocument2SizeValid = false;
        }
      }

      if ($scope.additionalDocument3 !== undefined) {
        $scope.additionalDocument3SizeKB =
          $scope.additionalDocument3.size / 1000;
        $scope.additionalDocument3SizeMB =
          $scope.additionalDocument3.size / 1000000;

        // 20MB maximum additionalDocument size
        if ($scope.additionalDocument3.size < 20000000) {
          $scope.additionalDocument3SizeValid = true;
        } else {
          $scope.additionalDocument3SizeValid = false;
        }
      }

      if ($scope.additionalDocument4 !== undefined) {
        $scope.additionalDocument4SizeKB =
          $scope.additionalDocument4.size / 1000;
        $scope.additionalDocument4SizeMB =
          $scope.additionalDocument4.size / 1000000;

        // 20MB maximum additionalDocument size
        if ($scope.additionalDocument4.size < 20000000) {
          $scope.additionalDocument4SizeValid = true;
        } else {
          $scope.additionalDocument4SizeValid = false;
        }
      }

      if ($scope.additionalDocument5 !== undefined) {
        $scope.additionalDocument5SizeKB =
          $scope.additionalDocument5.size / 1000;
        $scope.additionalDocument5SizeMB =
          $scope.additionalDocument5.size / 1000000;

        // 20MB maximum additionalDocument size
        if ($scope.additionalDocument5.size < 20000000) {
          $scope.additionalDocument5SizeValid = true;
        } else {
          $scope.additionalDocument5SizeValid = false;
        }
      }

      // File type validation
      var validFormats = [
        "gif",
        "png",
        "jpg",
        "jpeg",
        "bmp",
        "pdf",
        "doc",
        "docx"
      ];

      if ($scope.additionalDocument1 !== undefined) {
        var ext1 = $scope.additionalDocument1.name
          .split(".")
          .pop()
          .toLowerCase();
        $scope.additionalDocument1TypeValid = validFormats.includes(ext1);
      }

      if ($scope.additionalDocument2 !== undefined) {
        var ext2 = $scope.additionalDocument2.name
          .split(".")
          .pop()
          .toLowerCase();
        $scope.additionalDocument2TypeValid = validFormats.includes(ext2);
      }

      if ($scope.additionalDocument3 !== undefined) {
        var ext3 = $scope.additionalDocument3.name
          .split(".")
          .pop()
          .toLowerCase();
        $scope.additionalDocument3TypeValid = validFormats.includes(ext3);
      }

      if ($scope.additionalDocument4 !== undefined) {
        var ext4 = $scope.additionalDocument4.name
          .split(".")
          .pop()
          .toLowerCase();
        $scope.additionalDocument4TypeValid = validFormats.includes(ext4);
      }

      if ($scope.additionalDocument5 !== undefined) {
        var ext5 = $scope.additionalDocument5.name
          .split(".")
          .pop()
          .toLowerCase();
        $scope.additionalDocument5TypeValid = validFormats.includes(ext5);
      }
    };

    $scope.uploadAdditionalDocuments = function (loan_id, member_id) {
      $upload
        .upload({
          url: "/uploadAdditionalDocuments",
          data: {
            loan_id: loan_id,
            member_id: member_id,
            document1: $scope.additionalDocument1,
            document2: $scope.additionalDocument2,
            document3: $scope.additionalDocument3,
            document4: $scope.additionalDocument4,
            document5: $scope.additionalDocument5
          }
        })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          location.reload();
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.deleteAdditionalDocument = function (loanId, document) {
      return $http({
        method: "POST",
        url: "index.php/deleteAdditionalDocument",
        params: {
          loanId: loanId,
          additional_document: document
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          location.reload();
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };

    $scope.clearInput = function () {
      $scope.uploadAddDocForm.$setUntouched();
    };
  }
]);
