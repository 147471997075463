main.service("DepartmentsService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Department = this;

	Department.getDepartments = function () {
		return $http({
			url: "index.php/getDepartments"
		});
	};
});
