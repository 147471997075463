" use strict";

var dependencies = [
  "ui.router",
  "xeditable",
  "ui.select",
  "ngSanitize",
  `angularjs-dropdown-multiselect`,
  "ui.bootstrap",
  "daterangepicker",
  "angular-loading-bar",
  "uiNotify",
  "angularFileUpload",
  "angularUtils.directives.dirPagination",
  "chart.js",
  "ngMessages",
  "ngMaterial"
];

var main = angular.module("main", dependencies);

main.config(function ($stateProvider, $urlRouterProvider) {
  $stateProvider

    .state("/", {
      url: "/home",
      templateUrl: "pages/home/dashboard.html"
    })

    .state("#/transactions", {
      url: "/transactions",
      templateUrl: "pages/home/transactions.html"
    })

    .state("#/transactions/deductions", {
      url: "/transactions/deductions",
      templateUrl: "pages/home/deductionList.html"
    })

    .state("#/transactions/withdrawals", {
      url: "/transactions/withdrawals",
      templateUrl: "pages/home/withdraws.html"
    })

    .state("#/transactions/repayments", {
      url: "/transactions/repayments",
      templateUrl: "pages/home/repayments.html"
    })

    .state("#/transactions/benefits", {
      url: "/transactions/benefits",
      templateUrl: "pages/home/benefits.html"
    })

    .state("#/transactions/dividends", {
      url: "/transactions/dividends",
      templateUrl: "pages/home/dividendTransactions.html"
    })

    .state("#/members/info/:memberId", {
      url: "/members/info/:memberId",
      templateUrl: "pages/home/info.html"
    })

    .state("#/members/memberinfo", {
      url: "/members/memberinfo",
      templateUrl: "pages/home/memberInfo.html"
    })

    .state("#/users/info/:userId", {
      url: "/users/info/:userId",
      templateUrl: "pages/home/userInformation.html"
    })
    .state("#/loans/info/:loanId", {
      url: "/loans/info/:loanId",
      templateUrl: "pages/home/loanInformation.html"
    })

    .state("#/loans/loaninfo/:loanId", {
      url: "/loans/loaninfo/:loanId",
      templateUrl: "pages/home/memberLoanInformation.html"
    })

    .state("#/users/role", {
      url: "/users/role",
      templateUrl: "pages/home/roles.html"
    })
    .state("#/settings/loans", {
      url: "/settings/loans",
      templateUrl: "pages/home/loanSetting.html"
    })

    .state("#/settings/deductions", {
      url: "/settings/deductions",
      templateUrl: "pages/home/deductions.html"
    })

    .state("#/settings/dividends", {
      url: "/settings/dividends",
      templateUrl: "pages/home/dividends.html"
    })

    .state("#/settings/departments", {
      url: "/settings/departments",
      templateUrl: "pages/home/departments.html"
    })

    .state("#/settings/benefits", {
      url: "/settings/benefits",
      templateUrl: "pages/home/benefitSettings.html"
    })

    .state("#/settings/registration-fees", {
      url: "/settings/registration-fees",
      templateUrl: "pages/home/registration-fees.html"
    })

    .state("#/settings/audit-trail", {
      url: "/settings/audit-trail",
      templateUrl: "pages/home/audit-trail.html"
    })

    .state("#/user/profile", {
      url: "/user/profile",
      templateUrl: "pages/home/profiles.html"
    })

    .state("#/users/members", {
      url: "/users/members",
      templateUrl: "pages/home/members.html"
    })

    .state("/loans", {
      url: "/loans",
      templateUrl: "pages/home/loans.html"
    })

    .state("/reports", {
      url: "/reports",
      templateUrl: "pages/home/reports.html"
    })

    .state("/permissions", {
      url: "/permissions",
      templateUrl: "pages/home/permissions.html"
    })

    .state("/users", {
      url: "/users",
      templateUrl: "pages/home/users.html"
    })

    .state("/settings", {
      url: "/settings",
      templateUrl: "pages/home/settings.html"
    })

    /* messaging*/

    .state("/404", {
      url: "/404",
      templateUrl: "pages/home/dashboard.html"
    });
  $urlRouterProvider.otherwise("/home");
});

main.run(function (editableOptions, editableThemes) {
  editableThemes.bs3.inputClass = "input-sm";
  editableThemes.bs3.buttonsClass = "btn-sm";
  editableOptions.theme = "bs3";
});
