main.factory("appFactory", function ($http) {
  " use strict";
  return {
    getList: function (modelname) {
      return $http({
        method: "GET",
        url: "index.php/getList",
        params: {
          modelname: modelname
        }
      });
    },

    getItem: function (id, modelname) {
      return $http({
        method: "GET",
        url: "index.php/getItem",
        params: {
          id: id,
          modelname: modelname
        }
      });
    },

    getFields: function (columns, modelname) {
      return $http({
        method: "GET",
        url: "index.php/getFields",
        data: {
          modelname: modelname,
          columns: columns
        }
      });
    },

    showItem: function (id, modelname) {
      return $http({
        method: "GET",
        url: "index.php/showItem",
        params: {
          id: id,
          modelname: modelname
        }
      });
    },

    postItem: function (formdata, modelname) {
      return $http({
        method: "POST",
        url: "index.php/postItem",
        params: {
          modelname: modelname
        },
        data: $.param(formdata),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
    },

    postList: function (dict, modelname) {
      return $http({
        method: "POST",
        url: "index.php/postList",
        data: {
          dict: dict,
          modelname: modelname
        }
      });
    },

    postExpenseList: function (formdata, rows) {
      return $http({
        method: "POST",
        url: "index.php/postExpenseList",
        data: { formdata: formdata, rows: rows }
      });
    },

    postBudgetList: function (formdata, rows) {
      return $http({
        method: "POST",
        url: "index.php/postBudgetList",
        data: { formdata: formdata, rows: rows }
      });
    },

    updateRow: function (id, data, modelname) {
      return $http({
        method: "PUT",
        url: "index.php/updateRow",
        params: {
          id: id,
          data: data,
          modelname: modelname
        }
      });
    },

    updateColumn: function (data, modelname) {
      return $http({
        method: "PUT",
        url: "index.php/updateColumn",
        params: {
          data: data,
          modelname: modelname
        }
      });
    },

    deleteItem: function (id, modelname) {
      return $http({
        method: "DELETE",
        url: "index.php/deleteItem",
        params: {
          id: id,
          modelname: modelname
        }
      });
    },

    deleteList: function (ids, modelname) {
      return $http({
        method: "POST", // 'post' because we send an array of ids
        url: "index.php/deleteList",
        data: {
          ids: ids,
          modelname: modelname
        }
      });
    },

    DeleteProduct: function (id) {
      return $http({
        method: "POST",
        url: "index.php/DeleteProduct",
        data: {
          id: id
        }
      });
    },

    // specific store requests

    postDisributorList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/storeDistributors",
        data: {
          dict: dict
        }
      });
    },

    postSupplierList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/storeSuppliers",
        data: {
          dict: dict
        }
      });
    },

    postPurchaseOrder: function (dict, rows) {
      return $http({
        method: "POST",
        url: "index.php/postPurchaseOrder",
        data: {
          dict: dict,
          rows: rows
        }
      });
    },

    postDebitNote: function (dict, rows) {
      return $http({
        method: "POST",
        url: "index.php/postDebitNote",
        data: {
          dict: dict,
          rows: rows
        }
      });
    },

    postPurchaseList: function (dict, rows) {
      return $http({
        method: "POST",
        url: "index.php/storePurchases",
        data: {
          dict: dict,
          rows: rows
        }
      });
    },

    postEmployeeList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postEmployeeList",
        data: {
          dict: dict
        }
      });
    },

    postScorepointList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postScorepointList",
        data: {
          dict: dict
        }
      });
    },

    postDistrubutionList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postDistrubutionList",
        data: {
          dict: dict
        }
      });
    },

    postCommissionDistributionList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postCommissionDistributionList",
        data: {
          dict: dict
        }
      });
    },

    postProductCategoriesList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postProductCategoriesList",
        data: {
          dict: dict
        }
      });
    },

    postProductSubCategoriesList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postProductSubCategoriesList",
        data: {
          dict: dict
        }
      });
    },

    postSaleList: function (formdata, products, trails, total_purchase, rate) {
      return $http({
        method: "POST",
        url: "index.php/storeSales",
        data: {
          dict: formdata,
          trails: trails,
          products: products,
          total_purchase: total_purchase,
          rate: rate
        }
      });
    },

    postProductsList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postProductsList",
        data: {
          dict: dict
        }
      });
    },

    postSalesDiscountList: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postSalesDiscountList",
        data: {
          dict: dict
        }
      });
    },

    postCommissions: function (dict) {
      return $http({
        method: "POST",
        url: "index.php/postCommissions",
        data: {
          dict: dict
        }
      });
    },

    taxOptions: function (id) {
      return $http({
        method: "GET",
        url: "index.php/taxOptions",
        params: {
          id: id
        }
      });
    },

    getProductPrices: function (prodId, customer_type) {
      return $http({
        method: "GET",
        url: "index.php/getProductPrices",
        params: {
          prodId: prodId,
          customer_type: customer_type
        }
      });
    },

    getAncestorScores: function (reference) {
      return $http({
        method: "GET",
        url: "index.php/getAncestorScores",
        params: {
          reference: reference
        }
      });
    },

    getAncestorBonuses: function (reference) {
      return $http({
        method: "GET",
        url: "index.php/getAncestorBouses",
        params: {
          reference: reference
        }
      });
    },

    DistributorProfile: function (id) {
      return $http({
        method: "GET",
        url: "index.php/DistributorProfile",
        params: {
          id: id
        }
      });
    },

    SupplierProfile: function (id, type) {
      return $http({
        method: "GET",
        url: "index.php/SupplierProfile",
        params: {
          id: id,
          type: type
        }
      });
    }
  };
});
