main.controller("UsersController", [
	"$scope",
	"UsersService",
	"RolesService",
	"notify",
	"$upload",
	"$http",
	function (
		$scope,
		UsersService,
		RolesService,
		notify,
		$upload,
		$http
	) {
		"use strict";

		/* Tabs in User Profile */
		var tabClasses;

		function initTabs() {
			tabClasses = ["", ""];
		}

		$scope.getTabClass = function (tabNum) {
			var n = tabNum;
			return tabClasses[tabNum];
		};

		$scope.getTabPaneClass = function (tabNum) {
			return "tab-pane " + tabClasses[tabNum];
		};

		$scope.setActiveTab = function (tabNum) {
			initTabs();
			tabClasses[tabNum] = "active";
		};

		initTabs();
		$scope.setActiveTab(1);


		$scope.getRoles = function () {
			RolesService.getRoles().success(function (data, status) {
				$scope.roles = data.roles;
			});
		}

		$scope.showUsers = function () {
			UsersService.showUsers().success(function (data) {
				$scope.users = data.users;
	
				var abilities = data.abilities;
				var fobidden = data.fobidden;
	
				angular.forEach(abilities, function (abilities) {
					if (abilities.name == "Add Users") {
						$scope.reg_user = "Yes";
					}
					if (abilities.name == "Assign Roles") {
						$scope.assign_user_roles = "Yes";
					}
					if (abilities.name == "Assign User Abilities") {
						$scope.assign_permission = "Yes";
					}
				});
	
				angular.forEach(fobidden, function (fobidden) {
					if (fobidden.name == "Add Users") {
						$scope.reg_user = "No";
					}
					if (fobidden.name == "Assign Roles") {
						$scope.assign_user_roles = "No";
					}
					if (fobidden.name.name == "Assign User Abilities") {
						$scope.assign_permission = "Yes";
					}
				});
			});
		}


		$scope.showProfile = function () {
			UsersService.showProfile().success(function (data) {
				$scope.profile = data.profile;
			});
		}

		$scope.getUserProfile = function () {
			UsersService.getUserProfile().success(function (data) {
				$scope.userProfile = data.userProfile;
	
				if (data.profilePicture.photo == null) {
					$scope.profilePicture = "default_user.png";
				} else {
					$scope.profilePicture = data.profilePicture.photo;
				}
			});
		}


		/*  Uploading profile picture */
		$scope.onFileSelect1 = function ($files) {
			$scope.photo = $files[0];

			// No photo validation
			if ($scope.photo.name !== undefined) {
				$scope.photoPresent = true;
			} else {
				$scope.photoPresent = false;
			}

			// photo size validation
			$scope.photoSizeKB = $scope.photo.size / 1000;
			$scope.photoSizeMB = $scope.photo.size / 1000000;

			// 5MB maximum photo size
			if ($scope.photo.size < 5000000) {
				$scope.photoSizeValid = true;
			} else {
				$scope.photoSizeValid = false;
			}

			// photo type validation
			var validFormats = ["gif", "bmp", "png", "jpg", "jpeg"];
			var ext = $scope.photo.name
				.split(".")
				.pop()
				.toLowerCase();
			$scope.photoTypeValid = validFormats.includes(ext);
		};

		$scope.uploadProfileImage = function (photo) {
			$upload
				.upload({
					url: "/uploadProfileImage",
					data: {
						photo: photo
					}
				})
				.success(function (response) {
					$scope.picture = {};

					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					location.reload();
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.clearProfileImage = function () {
			$scope.photo = {};
			$scope.myForm.$setPristine();
			$scope.myForm.$setUntouched();
		};

		$scope.uploadCapturedImage = function () {
			var photo = document.querySelector("#camera").src;

			$upload
				.upload({
					url: "/uploadCapturedImage",
					data: {
						photo: photo
					}
				})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					location.reload();
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.saveUser = function (formdata, selected, action) {
			return $http({
				method: "POST",
				url: "index.php/saveUser",
				params: {
					formdata: formdata,
					selected: selected
				}
			})
				.success(function (response) {
					if (action === "clear") {
						$scope.formdata = "";
					}
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					UsersService.showUsers().success(function (data, status) {
						$scope.users = data.users;
					});
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.update = function (id, formdata) {
			return $http({
				method: "POST",
				url: "index.php/update",
				params: { formdata: formdata, id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					UsersService.showUsers().success(function (data, status) {
						$scope.users = data.users;
					});
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.changePassword = function (formdata) {
			return $http({
				method: "POST",
				url: "index.php/changePassword",
				params: { formdata: formdata }
			})
				.success(function (response) {
					$scope.formdata = {};
					$scope.ChangePassword.$setPristine();
					$scope.ChangePassword.$setUntouched();
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.clearPasswords = function () {
			$scope.formdata = {};
			$scope.ChangePassword.$setPristine();
			$scope.ChangePassword.$setUntouched();
		};

		// $scope.HNPLC = function () {
		// 	var date = "2019-02-01 00:00:00";
		// 	return date;
		// };

		$scope.giveRole = function (id, selected) {
			return $http({
				method: "POST",
				url: "index.php/giveRole",
				params: { selected: selected, id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.delete = function (id) {
			return $http({
				method: "POST",
				url: "index.php/delete",
				params: { id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					UsersService.showUsers().success(function (data, status) {
						$scope.users = data.users;
					});
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.getDetails = function (id) {
			return $http({
				method: "POST",
				url: "index.php/getDetails",
				params: { id: id }
			}).success(function (data, status, response) {
				$scope.formdata = data.user;
				$scope.formdata.password = "";
			});
		};




	}
]);
