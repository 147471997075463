main.service("ProfilesService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Profile = this;

	// Not used code?
	Profile.showProfiles = function () {
		return $http({
			url: "index.php/showProfiles"
		});
	};
});
