main.controller("DeductionsController", [
  "$scope",
  "DeductionsService",
  "MembersService",
  "notify",
  "$http",
  function (
    $scope,
    DeductionsService,
    MembersService,
    notify,
    $http
  ) {
    "use strict";


    /* Shared code in Deduction List page and Deduction Settings page */

    // Pagination Column Index
    $scope.serial = 1;
    $scope.indexCount = function (newPageNumber) {
      $scope.itemPerPage = $scope.rowNumber.selected.value;
      $scope.serial = newPageNumber * $scope.itemPerPage - ($scope.itemPerPage - 1);
    };


    // Order Filter
    $scope.setOrderProperty = function (propertyName) {
      if ($scope.orderProperty === propertyName) {
        $scope.orderProperty = "-" + propertyName;
      } else if ($scope.orderProperty === "-" + propertyName) {
        $scope.orderProperty = propertyName;
      } else {
        $scope.orderProperty = propertyName;
      }
    };


    // Rows Per Page Filter 
    $scope.rowNumber = {};
    $scope.rowNumber.selected = { label: 10, value: 10 };
    $scope.rowNumbers = [
      { label: 10, value: 10 },
      { label: 30, value: 30 },
      { label: 50, value: 50 },
      { label: "All", value: "" }
    ];


    // Permissions
    MembersService.getUserAuthorization().success(function (data) {
      var abilities = data.abilities;
      var fobidden = data.fobidden;

      angular.forEach(abilities, function (abilities) {
        if (abilities.name == "Create Deductions") {
          $scope.create_deductions = "Yes";
        }
        if (abilities.name == "Export Deductions") {
          $scope.export_deductions = "Yes";
        }
      });

      angular.forEach(fobidden, function (fobidden) {
        if (fobidden.name == "Create Deductions") {
          $scope.create_deductions = "No";
        }
        if (fobidden.name == "Export Deductions") {
          $scope.export_deductions = "No";
        }
      });
    });



    /* Deduction List page */

    // Datepicker for Adding deduction
    $('input[name="singledate"]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment()
        .startOf("hour")
        .add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD",
        firstDay: 1
      }
    });


    // Daterange picker
    $(function () {
      $scope.dateRange = function (range) {
        var daterange = range.split("-");
        $scope.dateFrom = Date.parse(daterange["0"]);
        $scope.dateTo = Date.parse(daterange["1"]);
      };

      var start = moment().subtract(29, "days");
      var end = moment();

      function cb(start, end) {
        $("#reportrange span").html(
          start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
        );
      }

      
      $("#reportrange").daterangepicker(
        {
          locale: {
            format: "MMM DD,YYYY",
            firstDay: 1
          },
          showDropdowns: true,
          startDate: start,
          endDate: end,

          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days")
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month"),
              moment()
                .subtract(1, "month")
                .endOf("month")
            ],
            "This Year": [moment().startOf("year"), moment().endOf("year")],
            "Last Year": [
              moment()
                .subtract(1, "year")
                .startOf("year"),
              moment()
                .subtract(1, "year")
                .endOf("year")
            ]
          }
        },
        cb
      );

      cb(start, end);
    });


    // Select members in Add & Edit deductions
    $scope.selectedMember = [];

    $scope.customText = { buttonDefaultText: "Select or search..." };

    $scope.Mysettings = {
      template:
        "<b>{{option.first_name}} " + "{{option.middle_name}} " + "{{option.last_name}}</b>",
      scrollableHeight: "250px",
      styleActive: true,
      scrollable: true,
      enableSearch: true,
      keyboardControls: true
    };


    // Show deductions list in Deduction List page
    $scope.displayDeduction = function () {
      DeductionsService.displayDeduction().success(function (data) {
        $scope.deductionList = data.deductionList;
      });
    }


    // Show members in Add & Edit deductions form
    // $scope.showMembers = function () {
    //   MembersService.showMembers().success(function (data) {
    //     $scope.members = data.members;
    //   });
    // }
    $scope.showCurrentMembers = function () {
      MembersService.showCurrentMembers().success(function (data) {
        $scope.members = data.members;
      });
    }


    // Show active deductions in Add & Edit deductions form & page filter
    $scope.getActiveDeductions = function () {
      DeductionsService.getActiveDeductions().success(function (data) {
        $scope.active_deductions = data.active_deductions;

        $scope.trans_type = {};
        $scope.trans_type.selected = { label: "All", value: "" };
        $scope.trans_types = [{ label: "All", value: "" }];
  
        var deductions = data.active_deductions;
        angular.forEach(deductions, function (deductions) {
          $scope.trans_types.push({
            label: deductions.group_name,
            value: deductions.group_name
          });
        });

      });
    };


    $scope.createDeductionList = function (
      deduction,
      effective_date,
      amount,
      selectedMember
    ) {
      return $http({
        method: "POST",
        url: "index.php/createDeductionList",
        params: {
          deduction: deduction,
          effective_date: effective_date,
          amount: amount,
          "selectedMember[]": selectedMember
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DeductionsService.displayDeduction().success(function (data) {
            $scope.deductionList = data.deductionList;
          });

          $scope.clearAddDeductionListModal();

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };
    

    $scope.clearAddDeductionListModal = function() {
      $scope.selectedMember = [];
      if ($scope.active_deductions.id) {
        $scope.active_deductions.id.selected = '';
      }
      $scope.deduction_amount = null;
      $scope.date = '';
      $scope.addDeductionForm.$setPristine();
      $scope.addDeductionForm.$setUntouched();
      
    }


    $scope.editDeductionList = function (
      deduction,
      effective_date,
      amount,
      selectedMember,
      id
    ) {
      return $http({
        method: "POST",
        url: "index.php/editDeductionList",
        params: {
          deduction: deduction,
          effective_date: effective_date,
          amount: amount,
          selectedMember: selectedMember,
          id: id
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DeductionsService.displayDeduction().success(function (data) {
            $scope.deductionList = data.deductionList;
          });
        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteDeduction = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteDeduction",
        params: {
          id: id
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DeductionsService.displayDeduction().success(function (data) {
            $scope.deductionList = data.deductionList;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.getDeductionDetailsEdit = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionDetailsEdit/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata = data.deduction;
        $scope.min_amount_edit_form = data.deduction.min_amount;
        $scope.max_amount_edit_form = data.deduction.max_amount;
        $scope.getEditDeductionState();
      });
    };


    $scope.getDeductionDetailsDelete = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionDetailsDelete/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata = data.deduction;
      });
    };


    $scope.getAddDeductionState = function () {
      var deductions = $scope.active_deductions;

      angular.forEach(deductions, function (deductions) {
        if (deductions.id == $scope.active_deductions.id.selected.id) {
          $scope.min_amount = deductions.min_amount;
          $scope.max_amount = deductions.max_amount;
        }
      });

      var amount = Number($scope.deduction_amount);
      var max_amount = Number($scope.max_amount);
      var min_amount = Number($scope.min_amount);
      $scope.above_max = amount > max_amount;
      $scope.below_min = amount < min_amount;
    };


    $scope.getEditDeductionState = function () {
      var deductions = $scope.active_deductions;

      angular.forEach(deductions, function (deductions) {
        if (deductions.id == $scope.formdata.predefined_deduction_id) {
          $scope.min_amount_edit_form = deductions.min_amount;
          $scope.max_amount_edit_form = deductions.max_amount;
        }
      });

      var amount = Number($scope.formdata.amount);
      var max_amount = Number($scope.max_amount_edit_form);
      var min_amount = Number($scope.min_amount_edit_form);
      $scope.above_max_edit_form = amount > max_amount;
      $scope.below_min_edit_form = amount < min_amount;
    };



    /* Deduction Settings page */

    // Page Tabs in Deduction Settings page
    var tabClasses;

    function initTabs() {
      tabClasses = ["", "", "", ""];
    }

    $scope.getTabClass = function (tabNum) {
      var n = tabNum;
      return tabClasses[tabNum];
    };

    $scope.getTabPaneClass = function (tabNum) {
      return "tab-pane " + tabClasses[tabNum];
    };

    $scope.setActiveTab = function (tabNum) {
      initTabs();
      tabClasses[tabNum] = "active";
    };

    initTabs();
    $scope.setActiveTab(1);

    $scope.onAdd = function ($type) {
      $scope.transaction = $type;
    };


    // Rows per page filter
    $scope.rowNumber2 = {};
    $scope.rowNumber2.selected = {label: 10, value: 10};
    $scope.rowNumbers2 = [ 
        {label: 10, value: 10},
        {label: 30, value: 30},
        {label: 50, value: 50},
        {label: 'All', value: ''}
        ];


    // Rows per page filter
    $scope.rowNumber3 = {};
    $scope.rowNumber3.selected ={label: 10, value: 10};
    $scope.rowNumbers3 = [ 
        {label: 10, value: 10},
        {label: 30, value: 30},
        {label: 50, value: 50},
        {label: 'All', value: ''}
        ];


    // Rows per page filter
    $scope.rowNumber4 = {};
    $scope.rowNumber4.selected ={label: 10, value: 10};
    $scope.rowNumbers4 = [ 
        {label: 10, value: 10},
        {label: 30, value: 30},
        {label: 50, value: 50},
        {label: 'All', value: ''}
        ];


    // Status filter    
    $scope.is_active_filter = {};
    $scope.is_active_filter.selected = { label: "Active", value: "1" };
    $scope.is_active_filters = [
      { label: "All", value: "" },
      { label: "Active", value: "1" },
      { label: "Inactive", value: "0" }
    ];


    // Datepicker for Adding Deduction Settings
    $('input[name="start_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date_save"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    // Datepicker for Editing Deduction Settings
    $('input[name="start_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    $('input[name="end_date"]').daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      showDropdowns: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "YYYY-MM-DD hh:mm A",
        firstDay: 1,
      },
    });


    // Show all deductions in Deduction Settings page
    $scope.getDeductions = function () {
      DeductionsService.getDeductions().success(function (data) {
        $scope.deductions = data.deductions;
      });
    }


    $scope.saveDeductionSettings = function (formdata, transaction) {
      return $http({
        method: "POST",
        url: "index.php/saveDeductionSettings",
        params: {
          transaction: transaction,
          formdata: formdata
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          // Clear Add Deduction Settings Form and reset status
          $scope.clearAddDeductionSettingsModal();
          
          DeductionsService.getDeductions().success(function (data) {
            $scope.deductions = data.deductions;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    // Clear Add Deduction Settings Form and reset status
    $scope.clearAddDeductionSettingsModal = function () {
      $scope.formdata.group_name = "";
      $scope.formdata.status = "1";
      $scope.formdata.start_date = "";
      $scope.formdata.end_date = "";
      $scope.formdata.min_amount = null;
      $scope.formdata.max_amount = null;
      $scope.formdata.interval = "";
      $scope.formdata.length = "";
      $scope.Trans.$setPristine();
      $scope.Trans.$setUntouched();
    };


    $scope.updateDeductionSettings = function (formdata) {
      return $http({
        method: "POST",
        url: "index.php/updateDeductionSettings",
        params: {
          formdata: formdata
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DeductionsService.getDeductions().success(function (data) {
            $scope.deductions = data.deductions;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.deleteDeductionSetting = function (id) {
      return $http({
        method: "POST",
        url: "index.php/deleteDeductionSetting",
        params: {
          id: id
        }
      })
        .success(function (response) {
          notify({
            message: response,
            template: "pages/notify/success.html",
            position: "center"
          });

          DeductionsService.getDeductions().success(function (data) {
            $scope.deductions = data.deductions;
          });

        })
        .error(function (response) {
          notify({
            message: response,
            template: "pages/notify/error.html",
            position: "center"
          });
        });
    };


    $scope.getDeductionSettingDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionSettingDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata2 = data.deductions;
      });
    };


    $scope.getSavingsDeductionSettingDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionSettingDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata3 = data.deductions;
      });
    };


    $scope.getContributionsDeductionSettingDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionSettingDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata4 = data.deductions;
      });
    };


    $scope.getSharesDeductionSettingDetails = function (id) {
      return $http({
        method: "POST",
        url: "index.php/getDeductionSettingDetails/",
        params: { id: id }
      }).success(function (data) {
        $scope.formdata5 = data.deductions;
      });
    };

        

  }
]);
