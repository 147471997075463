main.controller("ProfilesController", [
	"$rootScope",
	"$scope",
	"MembersService",
	"restService",
	"$modal",
	"$log",
	"notify",
	"$filter",
	"$http",
	function (
		$rootScope,
		$scope,
		MembersService,
		restService,
		$modal,
		$log,
		notify,
		$filter,
		$http
	) {
		"use strict";

		$scope.showProfile = function (id) {
			return $http({
				method: "POST",
				url: "index.php/showProfile",
				params: { id: id }
			}).success(function (data, status, response) {
				$scope.profiles = data.profiles;
				$scope.formdata = data.profiles;
			});
		};

		// $scope.HNPLC = function () {
		// 	var date = "2019-02-01 00:00:00";
		// 	return date;
		// };

		MembersService.showMembers().success(function (data, status) {
			$scope.members = data.members;
		});
	}
]);
