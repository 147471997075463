"use strict";
angular.module("filters", []).filter("currency", function () {
	return function (number, currencyCode) {
		var currency = {
			TZS: "TZS ",
			USD: "$",
			MIXED: "~"
		},
			thousand,
			decimal,
			format;
		if ($.inArray(currencyCode, ["TZS", "USD", "MIXED"]) >= 0) {
			thousand = ",";
			decimal = ".";
			format = "%s%v";
		} else {
			thousand = ",";
			decimal = ".";
			format = "%s%v";
		}
		return accounting.formatMoney(number, { symbol: "TZS", format: "%v %s" });
	};
});

main.filter("strToInt", function () {
	return function (input) {
		if (input) {
			return parseInt(input, 10);
		} else {
			return "Not set";
		}
	};
});

main.filter("dateFormat", function ($filter) {
	return function (input) {
		if (input == null) {
			return "";
		}
		var _date = $filter("date")(new Date(input), "MMMM d, y");

		return _date;
	};
});

main.filter("dateFormatShortened", function ($filter) {
	return function (input) {
		if (input == null) {
			return "";
		}
		var _date = $filter("date")(new Date(input), "MMM d, y");

		return _date;
	};
});

main.filter("dateRange", function () {
	return function (items, dateFrom, dateTo) {
		var filtered = [];
		var from_date = dateFrom;
		var to_date = dateTo + 86400000; // Add 1 day in milliseconds

		if (dateTo) {
			angular.forEach(items, function (item) {
				if (
					Date.parse(item.effective_date) >= from_date &&
					Date.parse(item.effective_date) <= to_date
				) {
					filtered.push(item);
				}
			});
		} else {
			angular.forEach(items, function (item) {
				filtered.push(item);
			});
		}

		return filtered;
	};
});

main.filter("dateRange2", function () {
	return function (items, dateFrom2, dateTo2) {
		var filtered = [];
		var from_date = dateFrom2;
		var to_date = dateTo2 + 86400000; // Add 1 day in milliseconds;
		if (dateTo2) {
			angular.forEach(items, function (item) {
				if (
					Date.parse(item.effective_date) >= from_date &&
					Date.parse(item.effective_date) <= to_date
				) {
					filtered.push(item);
				}
			});
		} else {
			angular.forEach(items, function (item) {
				filtered.push(item);
			});
		}

		return filtered;
	};
});

main.filter("dateRange3", function () {
	return function (items, dateFrom3, dateTo3) {
		var filtered = [];
		var from_date = dateFrom3;
		var to_date = dateTo3 + 86400000; // Add 1 day in milliseconds;
		if (dateTo3) {
			angular.forEach(items, function (item) {
				if (
					Date.parse(item.effective_date) >= from_date &&
					Date.parse(item.effective_date) <= to_date
				) {
					filtered.push(item);
				}
			});
		} else {
			angular.forEach(items, function (item) {
				filtered.push(item);
			});
		}

		return filtered;
	};
});

main.filter("dateRange4", function () {
	return function (items, dateFrom, dateTo) {
		var filtered = [];
		var from_date = dateFrom;
		var to_date = dateTo + 86400000; // Add 1 day in milliseconds;;

		if (dateTo) {
			angular.forEach(items, function (item) {
				if (
					Date.parse(item.effective_date) >= from_date &&
					Date.parse(item.effective_date) <= to_date
				) {
					filtered.push(item);
				}
			});
		} else {
			angular.forEach(items, function (item) {
				filtered.push(item);
			});
		}
		return filtered;
	};
});

// date filter for Audit Trail
main.filter('dateRange5', function() {
    return function( items, dateFrom, dateTo ) {
        var filtered = [];
        var from_date = dateFrom;
        var to_date = dateTo + 86400000; // Add 1 day in milliseconds

        if(dateTo){
              angular.forEach(items, function(item) {
            if(Date.parse(item.created_at) >= from_date && Date.parse(item.created_at) <= to_date) {
                filtered.push(item);
               
            }
        });
            } else{
                angular.forEach(items,function(item){
                     filtered.push(item);

                });
            }
      
        return filtered;
    };
});

main.filter("sum", function () {
	return function (data, key) {
		if (typeof data === "undefined" || typeof key === "undefined") {
			return 0;
		}
		var sum = 0;
		for (var i = data.length - 1; i >= 0; i--) {
			if (
				typeof data[i][key] === "string" ||
				typeof data[i][key] === "number"
			) {
				sum += parseFloat(data[i][key]);
			}
		}
		return sum;
	};
});

main.filter("urlEncode", function () {
	return window.encodeURIComponent;
});

main.filter("filterMonth", function () {
	return function (datas, key) {
		var key = parseInt(key - 1);
		if (datas == null) {
			return "";
		}

		// what if this month has no data, return all records(how?madofe, how?)

		if (key == 12 || key == 0 || key == null) {
			return datas;
		}

		if (datas.length > 0) {
			return datas.filter(function (data) {
				return (
					new Date(data.sales_date).getMonth() == key ||
					new Date(data.purchase_dateeffective_date).getMonth() == key ||
					new Date(data.effective_date).getMonth() == key ||
					new Date(data.purch_date).getMonth() == key ||
					new Date(data.order_date).getMonth() == key ||
					new Date(data.followup_date).getMonth() == key ||
					new Date(data.expiry_date).getMonth() == key ||
					new Date(data.created_at).getMonth() == key ||
					new Date(data.settlement_date).getMonth() == key ||
					new Date(data.sign_date).getMonth() == key
				);
			});
		}
	};
});

main.filter("stringFilter", function () {
	return function (datas, key) {
		var field = key[0];
		var value = key[1];

		if (datas == null) {
			return "";
		}

		if (value == null) {
			return datas;
		}

		if (datas.length > 0) {
			return datas.filter(function (data) {
				return data[field] == value;
			});
		}
	};
});

main.filter("filterMonthMultField", function () {
	return function (datas, key) {
		var field = key[0];
		var value = key[1];

		var value = parseInt(value - 1);
		if (datas == null) {
			return "";
		}

		if (value == 12 || value == 0 || value == null) {
			return datas;
		}

		if (datas.length > 0) {
			return datas.filter(function (data) {
				return new Date(data[field]).getMonth() == value;
			});
		}
	};
});

main.filter("abs", function () {
	return function (num) {
		return Math.abs(num);
	};
});
