main.service("ReportsService", function (
	modalService,
	appFactory,
	notify,
	$modal,
	$http
) {
	"use strict";
	var Report = this;

	Report.getReports = function () {
		return $http({
			url: "index.php/getReports"
		});
	};

	Report.getReport = function (date) {
		return $http({
			url: "index.php/getReport",
			method: "POST",
			data: {
				date: date
			}
		});
	};
});
