main.controller("RolesController", [
	"$rootScope",
	"$stateParams",
	"$scope",
	"RolesService",
	"UsersService",
	"MembersService",
	"notify",
	"$http",
	function (
		$rootScope,
		$stateParams,
		$scope,
		RolesService,
		UsersService,
		MembersService,
		notify,
		$http
	) {
		"use strict";

		$scope.getPermissions = function () {
			RolesService.getPermissions().success(function (data) {
				$scope.permissions = data.permissions;
			});
		}

		$scope.getRoles = function () {
			RolesService.getRoles().success(function (data) {
				$scope.roles = data.roles;
			});
		}

		// Populate User Roles & Permissions page with users for the Search Menu
		$scope.showUsers = function () {
			UsersService.showUsers().success(function(data){
				$scope.users = data.users;
			});
		}
		
		MembersService.getUserAuthorization().success(function (data) {
			var abilities = data.abilities;
			var fobidden = data.fobidden;

			angular.forEach(abilities, function (abilities) {
				if (abilities.name == "Assign Roles") {
					$scope.assign_user_roles = "Yes";
				}
				if (abilities.name == "Assign User Abilities") {
					$scope.assign_permission = "Yes";
				}
				if (abilities.name == "Create Roles") {
					$scope.create_roles = "Yes";
				}
				if (abilities.name == "Assign Role Abilities") {
					$scope.change_role_abilities = "Yes";
				}
			});

			angular.forEach(fobidden, function (fobidden) {
				if (fobidden.name == "Assign Roles") {
					$scope.assign_user_roles = "No";
				}
				if (fobidden.name == "Assign User Abilities") {
					$scope.assign_permission = "No";
				}
				if (fobidden.name == "Create Roles") {
					$scope.create_roles = "No";
				}
				if (abilities.name == "Assign Role Abilities") {
					$scope.change_role_abilities = "Yes";
				}
			});
		});

		$scope.userId = $stateParams.userId;

		$scope.selectedPermissions = [];

		$scope.customText = { buttonDefaultText: "Select Permissions" };

		$scope.Mysettings = {
			template: "<b>{{option.name}}</b>",
			scrollableHeight: "450px",
			styleActive: true,
			scrollable: true,
			enableSearch: true,
			keyboardControls: true
		};

		var tabClasses;

		function initTabs() {
			tabClasses = ["", "", "", ""];
		}

		$scope.getTabClass = function (tabNum) {
			var n = tabNum;
			return tabClasses[tabNum];
		};

		$scope.getTabPaneClass = function (tabNum) {
			return "tab-pane " + tabClasses[tabNum];
		};

		$scope.setActiveTab = function (tabNum) {
			initTabs();
			tabClasses[tabNum] = "active";
		};

		initTabs();
		$scope.setActiveTab(1);

		// Order Filter
		$scope.setOrderProperty = function (propertyName) {
			if ($scope.orderProperty === propertyName) {
				$scope.orderProperty = "-" + propertyName;
			} else if ($scope.orderProperty === "-" + propertyName) {
				$scope.orderProperty = propertyName;
			} else {
				$scope.orderProperty = propertyName;
			}
		};

		$scope.saveRole = function (formdata, action) {
			return $http({
				method: "POST",
				url: "index.php/saveRole",
				params: { formdata: formdata }
			})
				.success(function (response) {
					if (action === "clear") {
						$scope.formdata.item_name = "";
						$scope.formdata.description = "";
					}
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					$scope.getRoles();

				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		// $scope.HNPLC = function () {
		// 	var date = "2019-02-01 00:00:00";
		// 	return date;
		// };

		$scope.giveRole = function (id, selected) {
			return $http({
				method: "POST",
				url: "index.php/giveRole",
				params: { selected: selected, id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					// UsersService.showUsers().success(function (data, status) {
					// 	$scope.users = data.users;
					// });

					// window.location.reload();
					$rootScope.getUserInfo(id);
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		// $scope.savePermission = function (permission) {
		// 	return $http({
		// 		method: "POST",
		// 		url: "index.php/savePermission",
		// 		params: { permission: permission }
		// 	})
		// 		.success(function (response) {
		// 			notify({
		// 				message: response,
		// 				template: "pages/notify/success.html",
		// 				position: "center"
		// 			});

		// 			RolesService.getPermissions().success(function (data, status) {
		// 				$scope.system_permission = data.system_permission;
		// 			});
		// 		})
		// 		.error(function (response) {
		// 			notify({
		// 				message: response,
		// 				template: "pages/notify/error.html",
		// 				position: "center"
		// 			});
		// 		});
		// };

		$scope.updateRole = function (id, formdata) {
			return $http({
				method: "POST",
				url: "index.php/updateRole",
				params: { formdata: formdata, id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					$scope.getRoles();
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$rootScope.getUserInfo = function (userId) {
			RolesService.getUserInfo(userId)
				.success(function (data, status) {
					// if (a) {
					// 	$state.go("#/users/info/:userId", { userId: data.user.id });
					// }
					$scope.user = data.user;
					$scope.user_roles = data.roles;
					$scope.abilities = data.abilities;
					$scope.forbiddenAbilities = data.forbiddenAbilities;

					$scope.nextId = data.nextId;
					$scope.prevId = data.prevId;
				})
				.error(function (data, status) {
					alert("something went wrong");
				});
		};

		$scope.deleteRole = function (id) {
			return $http({
				method: "POST",
				url: "index.php/deleteRole",
				params: { id: id }
			})
				.success(function (response) {
					notify({
						message: response,
						template: "pages/notify/success.html",
						position: "center"
					});

					$scope.getRoles();
				})
				.error(function (response) {
					notify({
						message: response,
						template: "pages/notify/error.html",
						position: "center"
					});
				});
		};

		$scope.removeRoleOrAbility = function (data, id, action) {
			if ((data, id)) {
				return $http({
					method: "POST",
					url: "index.php/removeRoleOrAbility",
					params: {
						data: data,
						id: id,
						action: action
					}
				})
					.success(function (response) {
						notify({
							message: response,
							template: "pages/notify/success.html",
							position: "center"
						});
						// RolesService.getRoles().success(function (data, status) {
						// 	$scope.roles = data.roles;
						// });

						// window.location.reload();
						$rootScope.getUserInfo(id);
					})
					.error(function (response) {
						notify({
							message: response,
							template: "pages/notify/error.html",
							position: "center"
						});
					});
			}
		};

		$scope.getRoleDetails = function (id) {
			return $http({
				method: "POST",
				url: "index.php/getRoleDetails",
				params: { id: id }
			}).success(function (data, status, response) {
				$scope.role = data.role;
				$scope.formdata = data.role;
			});
		};

		$scope.assignPermission = function (group, formdata, permission, selected) {
			$scope.assignTo = group;
			if ($scope.assignTo == "User") {
				UsersService.showUsers().success(function (data, status) {
					$scope.group = data.users;
				});
			}

			if ($scope.assignTo == "Role") {
				RolesService.getRoles().success(function (data, status) {
					// $scope.group = data.roles;
					$scope.group = [];
					var role = data.roles;

					angular.forEach(role, function (role) {
						if (role.name !== "Member" && role.name !== "Admin") {
							$scope.group.push(role);
						}
					});
				});
			}

			if ((group, formdata, permission)) {
				var a = 1;
			}
			if (selected) {
				var a = 1;
			}

			if (a) {
				return $http({
					method: "POST",
					url: "index.php/assignPermission",
					params: {
						group: group,
						formdata: formdata,
						permission: permission,
						"selected[]": selected
					}
				})
					.success(function (response) {
						notify({
							message: response,
							template: "pages/notify/success.html",
							position: "center"
						});
					})
					.error(function (response) {
						notify({
							message: response,
							template: "pages/notify/error.html",
							position: "center"
						});
					});
			}
		};
	}
]);
