main.controller("ReportsController", [
	"$rootScope",
	"$scope",
	"ReportsService",
	"MembersService",
	"restService",
	"$modal",
	"$log",
	"notify",
	"$filter",
	"$http",
	function (
		$rootScope,
		$scope,
		ReportsService,
		MembersService,
		restService,
		$modal,
		$log,
		notify,
		$filter,
		$http
	) {
		"use strict";

		ReportsService.getReports().success(function (data) {
			$scope.reports = data;
		});

		MembersService.getUserAuthorization().success(function (data) {
			var abilities = data.abilities;
			var fobidden = data.fobidden;

			angular.forEach(abilities, function (abilities) {
				if (abilities.name == "Export Reports") {
					$scope.export_reports = true;
				}
			});

			angular.forEach(fobidden, function (fobidden) {
				if (fobidden.name == "Export Reports") {
					$scope.export_reports = false;
				}
			});
		});

		$(function () {
			$scope.dateRange = function (range) {
				var daterange = range.split("-");
				$scope.dateFrom = Date.parse(daterange["0"]);
				$scope.dateTo = Date.parse(daterange["1"]);
			};

			var start = moment().subtract(29, "days");
			var end = moment();

			function cb(start, end) {
				$("#reportrange span").html(
					start.format("YYYY-MM-DD") + "-" + end.format("YYYY-MM-DD")
				);
			}

			$("#reportrange").daterangepicker(
				{
					locale: {
						format: "MMM DD, YYYY",
						firstDay: 1
					},
					showDropdowns: true,
					startDate: start,
					endDate: end,
					ranges: {
						"This Year": [moment().startOf("year"), moment().endOf("year")],
						"Last Year": [
							moment()
								.subtract(1, "year")
								.startOf("year"),
							moment()
								.subtract(1, "year")
								.endOf("year")
						]
					}
				},
				cb
			);

			cb(start, end);
		});

		$scope.checkDate = function (date) {
			ReportsService.getReport(date).success(function (data) {
				$scope.reports = data;
			});
		};
	}
]);
